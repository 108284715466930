// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { projectCarouselData, projectDataType } from "./types";
import {
  ProjectList2,
  QCInitiated,
  sectionList,
  settingList1,
  ProjectInQueue,
  settingList,
} from '../__mocks__/KanbanInterface';
import { Alert, Platform } from "react-native";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ComponentState } from "react";
interface ApiData {
  contentType: string,
  method: string,
  endPoint: string,
  body?: object
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  projects: projectDataType,
  statusFlow: string[],
  data: {
    history: { location: { pathname: string } },
    projectStatusColors: projectCarouselData["projectStatusColors"],
    projects: projectDataType
  },
}

interface statusList {
  status:string,
  count:number,
  colorCode:string,
}
interface RoomList {
    id: string,
    type:string,
    attributes: {
      id: number,
      room_id: number,
      bx_block_profile_project_id: number,
      room_name:string
    }
  }

  interface TaskList {
          id: string,
          type: string,
          attributes: {
            id: number,
            account_id: string,
            name:string,
        }        
  }

  interface PriorityList {
    id:string,
    Priority_name:string,
    PriorityBackgroundColor: string,
    PriorityColor: string, 
     
    
  }




interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  ActiveKeyBoardControl: boolean,
  currentSlide: number,
  prevSlide: number,
  isVertical: boolean
  startDate: string,
  endDate: string,
  email: string,
  password: string,
  selectedStartDate: string;
  selectedEndDate: string;
  modalVisible: boolean;
  project_in_Queue: Array<ProjectInQueue>
  qc_Initiated: Array<QCInitiated>;
  statusFlow: string[]
  statusData: { attributes: { color: string, section_type: string; name: string } }[]
  settingData: { name: string, direction: string, theme: string, active: boolean }
  isHorizontal: boolean
  theam: string
  dataLength: number,
  toggle:boolean,
  isBulkAction:boolean,
  inputName:string,
  statusList:statusList[],
  priority:string;
  anchorEl:any;
  selectedAction:string;
  subMenuOpen: boolean;
  subMenuAnchorEl:any;
 isTaskListSelected:boolean;
 isAssignMemberModalOpen:boolean;
 selectedUsers: any;
 memberError:string;
 users: any;
 messageToggle: boolean;
 tostifymessage: string;
 status: string;
 isFilterModal:boolean;
 projectId:number;
 isRoomSelected:string[];
 roomList:RoomList[];
 isRoomListOpen:boolean;
 taskList:TaskList[];
 isTaskListSelectedFilter:string[];
 isTaskListOpen:boolean;
 isPriorityOpen:boolean;
 isPriorityList:PriorityList[];
 isPrioritySelected:string[];
 projectName:string;
 kanbanData:string[];
 isProjectTaskList:string[];
 isTaskListArrayValue:string[];
 roomDataSelected: string[];
 searchText:string; 
 priorityDropdownOpen:any;
 isTaskListDetailOpen:boolean;
 selectedStatus :string;
  statuses:string[];
  anchorElStatus:any;
  priorities:string[];
  anchorElPriority:any;
  selectedPriority:string;
  selectedTaskListID:string;
  currentRoomIDValue:string;
  taskListValues:any;
  toggleID:any;
  showAll:boolean;
  showComment:boolean;
  commenttext:string;
  commentListAll:any;
  attachmentList:any;
  showAttachments:boolean;
}

interface SS {
  id: any;

}

export default class KanbanBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  projectListApiId: string = ""
  sectionListApiId: string = ""
  settingListApiId: string = ""
  sectionAPIID: string = ""
  settingsAPIID: string = ""
  getTaskListCallId:string=""
  getProjectMembersCallId:string=""
  getRoomListCallId:string=""
  postAssignMemberCallId:string=""
  getProjectTaskListCallId:string=""
  changeTaskListStatusCallId:string=""
  taskListsValueId:string=""
  gettaskListIdValue:string=""
  getAllCommentListValueId:string=""
  getHitPostCommentApis:string =""
  getAttachmentListValue:string=""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      ActiveKeyBoardControl: false,
      currentSlide: 0,
      prevSlide: 0,
      isVertical: false,
      startDate: '19/02/2023',
      endDate: '25/03/2023',
      email: "",
      password: "",
      selectedStartDate: '',
      selectedEndDate: '',
      modalVisible: false,
      qc_Initiated: [],
      project_in_Queue: [],
      statusFlow:  [],
      statusData: [],
      showAll:false,
      showComment:false,
      settingData: { name: "", direction: "", theme: "", active: false },
      isHorizontal: false,
      theam: '',
      dataLength: 0,
      toggle:true,
      isBulkAction:false,
      inputName:"",
      statusList:[ { status: "To-Do", count: 2, colorCode: "#60A5FA" },
      { status: "In Progress", count: 1, colorCode: "#F59E0B" },
      { status: "Completed", count: 5, colorCode: "#34D399" },
      { status: "Blocked", count: 4, colorCode: "#F87171" },],
      priority:"low",
      anchorEl:null,
      selectedAction: '',
    subMenuOpen: false,
    subMenuAnchorEl: null,
    isTaskListSelected:false,
    isAssignMemberModalOpen:false,
    selectedTaskListID:'',
    toggleID:'',
    currentRoomIDValue:'',
    showAttachments:false,
    commenttext:'',
    selectedUsers: [],
    memberError:"",
    users: [],
    messageToggle: false,
    tostifymessage: "",
    status: "",
    isFilterModal:false,
    projectId:0,
    isRoomSelected:[],
    roomList:[],
    isRoomListOpen:false,
    taskList:[],
    isTaskListSelectedFilter:[],
    attachmentList:[],
    isTaskListOpen:false,
    isPriorityOpen:false,
    isPriorityList: [
      {
        id: "1",
        Priority_name: "Low",
        PriorityBackgroundColor: "#DBEAFE",
        PriorityColor: "#2563EB",     
      },
      {
        id: "2",
        Priority_name: "Mid",
        PriorityBackgroundColor: "#FEF3C7", 
        PriorityColor: "#D97706",          
      },
      {
        id: "3",
        Priority_name: "High",
        PriorityBackgroundColor: "#FEE2E2", 
        PriorityColor: "#DC2626",        
      }
    ],
    isPrioritySelected:[],
    projectName:"",
    kanbanData:[],
    isProjectTaskList:[],
    commentListAll:[],
    isTaskListArrayValue:[],
    roomDataSelected:[],
    searchText:"",
    priorityDropdownOpen: [],
    isTaskListDetailOpen:false,
    selectedStatus:"To Do",
    statuses:["To Do", "In Progress", "Completed", "Blocked", "Archived"],
    anchorElStatus:null,
    priorities: ["Low", "Mid", "High"],
    anchorElPriority:null,
    taskListValues:[],
    selectedPriority:"Mid"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const id = this.props.navigation?.getParam("project_id");
    this.setState({
      projectId:id
    })
    this.getTaskListApi()
    this.getProjectsMembers()
  this.getProjectTaskListApi()

    if (Platform.OS != "web") {
      this.projectList()
      this.sectionList()
      this.settingList()
      await getStorageData("USER_TOKEN")
    } else {
      this.props.data.history.location.pathname == "/bb_taskboard" && await this.ContentAPICall() && this.DataSettingAPICall()
    }
  }



async receive(from: string, message: Message) {
  runEngine.debugLog("Message Received", message);

  if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  switch (apiRequestCallId) {
    case this.projectListApiId:
      await this.projectListResponse(responseJson);
      break;

    case this.sectionListApiId:
      this.sectionListResponse(responseJson);
      break;

    case this.settingListApiId:
      this.settingListResponse(responseJson);
      break;

    case this.getTaskListCallId:
      this.handleGetTaskListResponse(responseJson);
      break;

    case this.getProjectMembersCallId:
      this.handleGetProjectMembersResponse(responseJson);
      break;

    case this.getRoomListCallId:
      this.handleGetRoomListResponse(responseJson);
      break;
    
    case this.postAssignMemberCallId:
      this.handleAssignMemberResponse(responseJson);
      break;
    
    case this.getProjectTaskListCallId:
      this.handleProjectTaskListResponse(responseJson)
      break;
    
    case this.changeTaskListStatusCallId:
      this.handleChangeTaskListStatusResponse(responseJson)
      break;

    case this.taskListsValueId:
      this.renderTaskListResponse(responseJson) 
      break;

    case this.getAllCommentListValueId:
      this.handleAllComments(responseJson)  
      break;

    case this.getHitPostCommentApis:
      this.setState({
        commenttext:""
      })  
      this.handleCommentListAPIs()
      break;
    case this.getAttachmentListValue:
      this.handleAttachmentListStore(responseJson)
      break;    

    default:
      runEngine.debugLog("Unhandled API request ID", apiRequestCallId);
      break;
  }
}

handleChangeTaskListStatusResponse = (responseJson:any) =>{
   this.getProjectTaskListApi()
   this.setState({
    isProjectTaskList:[]
   })
}

  handleGetRoomListResponse = (responseJson: any) => {
    const roomData = responseJson?.data?.data;
  
    if (Array.isArray(roomData)) {
      const roomList = roomData.map((room: any) => {
        return {
          id: room.id,
          type: room.type,
          attributes: {
            id: Number(room.id),
            room_id: room.attributes.room_id,
            bx_block_profile_project_id: room.attributes.bx_block_profile_project_id,
            room_name: room.attributes.room_name,
          },
        };
      });
      
      this.setState({
        roomList:roomList
      })

    }
  };
  

  handleGetProjectMembersResponse = (responseJson:any) =>{
    this.setState({users:responseJson.members})
  }

  handleGetTaskListResponse = (responseJson:any) =>{
    const taskData = responseJson?.data;
  
    if (Array.isArray(taskData)) {
      const taskDataList = taskData.map((task: any) => {
        return {
          id:task.id,
          type: task.type,
          attributes: {
            id: Number(task.id),
            name: task.attributes.name,
            account_id:task.attributes.account_id
          },
        };
      });
      
      this.setState({
        taskList:taskDataList
      })
    }
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (JSON.stringify(prevProps.statusFlow) != JSON.stringify(this.props.statusFlow)) {
      this.setState({ statusFlow: this.props.statusFlow })
    }
  }

  apiCallFunction = async (data: ApiData) => {
    const { contentType, method, endPoint, body, } = data;

    const header = {
      "Content-Type": contentType,
      "token": await getStorageData("USER_TOKEN")
    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return requestMessagee.messageId;
  };

  ContentAPICall = async () => {
    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  DataSettingAPICall = async () => {

    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  projectListResponse = async (responseJson: ProjectList2) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successOrderResponse(responseJson)
      }

    }
  }
  sectionListResponse = (responseJson: sectionList) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSectionListResponse(responseJson)
      }

    }
  }
  settingListResponse = (responseJson: settingList1) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSettingListResponse(responseJson)
      }

    }
  }

  projectList = async () => {
    this.projectListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_taskallocator/kanbanboard?from=${this.state.startDate}&to=${this.state.endDate}`,
    });
  }
  sectionList = async () => {
    this.sectionListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/sections`,
    });
  }
  settingList = async () => {
    this.settingListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/settings`,
    });
  }
  
  successOrderResponse(responseJson: ProjectList2) {
    this.setState(
      {
        project_in_Queue: responseJson['Project in Queue'],
        dataLength: responseJson['Project in Queue'].length
      }
    )
  }
  successSectionListResponse(responseJson: sectionList) {
    let tempArray: string[] = []
    responseJson.data.map((item: { attributes: { section_type: string } }) => {
      tempArray.push(item.attributes.section_type)
    })
    this.setState({ statusFlow: tempArray, statusData: responseJson.data })
  }

  successSettingListResponse(responseJson: settingList) {
    responseJson.data.map((item: { attributes: { active: boolean } }, index: number) => {
      if (item.attributes.active) {
        let theme = responseJson.data[index].attributes.theme
        let dirction = responseJson.data[index].attributes.direction === "vertical"
        this.setState({ theam: theme, isHorizontal: dirction })
      }
    })
  }


  goBack = () => {
    Alert.alert("Logout")
    removeStorageData("USER_TOKEN")
    const message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  getTaskListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
 
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTaskListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetTaskListEndPoint}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleToggle = async(tab: string) => {
    const isTeamsTab = tab === "Kanban Board View";
    localStorage.setItem("selectedTab", tab);
  
    this.setState({
      toggle: isTeamsTab,
    })
  };

  handleFilter =()=>{
    this.setState({
      isFilterModal:true
    })
    this.getRoomListApi()
  }

  handleSearch= (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ searchText: event.target.value });
  this.getProjectTaskListApi()
  };

  handleMenuOpen = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, subMenuOpen: false });
  };

  handleActionClick = (action: string, event: any) => {
    if (action === 'Change Status') {
      this.setState({
        selectedAction: action,
        subMenuOpen: true,
        subMenuAnchorEl: event.currentTarget,
      });
    } else {
      this.setState({ selectedAction: action,
        isAssignMemberModalOpen:true
       });
      this.handleMenuClose();
    }
  };

  handleSubMenuClick = async(status:string) => {
    const ids =this.state.roomDataSelected.map((item: any) => item.id);
    const roomIds = this.state.roomDataSelected.map((item: any) => item.roomId);  

    let statusValue
    if(status === "To Do"){
        statusValue="to_do"
    }else if(status == "In Progress"){
        statusValue="in_progress"
    }else if(status == "Completed"){
      statusValue= "complete"
    }else if(status == "Blocked"){
      statusValue="deleted"
    }
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,};

      const body = {
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changeTaskListStatusCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.patchChangeStatusEndPoint}/${this.state.projectId}/update_kanban_status?room_ids=${roomIds}&task_list_ids=${ids}&status=${statusValue}`);


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `${configJSON.httpPatchMethod}`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage
      );
   
    this.handleMenuClose();
  };

  handleChangeProjectTaskListSelect = (id: string, roomId: string) => {
    this.setState((prevState: ComponentState) => {
      const isAlreadySelected = prevState.isProjectTaskList.includes(id);
  
      const updatedProjectTaskList = isAlreadySelected
        ? prevState.isProjectTaskList.filter((projectId:any) => projectId !== id)
        : [...prevState.isProjectTaskList, id];
  
      // Update roomDataSelected array
      const updatedRoomDataSelected = isAlreadySelected
        ? prevState.roomDataSelected.filter((data:any) => data.roomId !== roomId)
        : [...prevState.roomDataSelected, { id, roomId }];
  
      return {
        isProjectTaskList: updatedProjectTaskList,
        roomDataSelected: updatedRoomDataSelected,
      };
    });
  };

  handleAssignMemberResponse = (responseJson:any)=>{
     this.setState({
      isAssignMemberModalOpen:false,
      isProjectTaskList:[]
     })
     this.setState({
      messageToggle: true,
      tostifymessage: "Member(s) successfully assigned.",
      status: "success",
    });
    setTimeout(() => {
      this.setState({ messageToggle: false, tostifymessage: "", status: "" });
    }, 5000);
  }


  handleAssignMember = async () => {
    const data = this.state.selectedUsers.map((item:any) => item.value);
    const ids = this.state.roomDataSelected.map((item: any) => item.id);
    const roomIds = this.state.roomDataSelected.map((item: any) => item.roomId);
    console.log(data,ids ,roomIds)
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const body = {
        "assignee_ids":data,
        "room_ids": roomIds,
        "task_ids":ids,
        "project_id": this.state.projectId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAssignMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PostAddAssignEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAssignMemberModalOpen: !prev.isAssignMemberModalOpen,
    }));

    handleFilterModal =()=>
    this.setState((prev) => ({
      isFilterModal: !prev.isFilterModal,
    }));

    getStatusBackgroundColor = (status:string) => {
      switch (status) {
        case 'To Do':
          return '#3B82F6'; 
        case 'In Progress':
          return '#FBBF24'; 
        case 'Completed':
          return '#34D399'; 
        case 'Blocked':
          return '#F87171'; 
        case 'Archived':
          return '#64748B'; 
        default:
          return ''; 
      }
    };

  getProjectsMembers = async () => {
      const token = await getStorageData("token")
      const header = {
        "Content-Type": "application/json",
        token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getProjectMembersCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.GetProjectMembersEndPoint}?project_id=${this.state.projectId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
  getRoomListApi = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoomListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetRoomListEndPoint}/${this.state.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeRoomSelected = (roomId:string) => {
    const { isRoomSelected } = this.state;
    if (isRoomSelected.includes(roomId)) {
      this.setState({
        isRoomSelected: isRoomSelected.filter((id) => id !== roomId),
      });
    } else {
      this.setState({
        isRoomSelected: [...isRoomSelected, roomId],
      });
    }
  };

  handleChangeTaskListSelected = (taskId:string) => {
    const { isTaskListSelectedFilter } = this.state;
    if (isTaskListSelectedFilter.includes(taskId)) {
      this.setState({
        isTaskListSelectedFilter: isTaskListSelectedFilter.filter((id) => id !== taskId),
      });
    } else {
      this.setState({
        isTaskListSelectedFilter: [...isTaskListSelectedFilter, taskId],
      });
    }
  };

  handleChangePriority = (priorityId: string) => {
    const { isPrioritySelected } = this.state;
    if (isPrioritySelected.includes(priorityId)) {
      this.setState({
        isPrioritySelected: isPrioritySelected.filter((id) => id !== priorityId),
      });
    } else {
      this.setState({
        isPrioritySelected: [...isPrioritySelected, priorityId],
      });
    }
  };
  

  ApplyFilterHandle =()=>{
    this.setState({
      isFilterModal:false
    })
    this.getProjectTaskListApi()
  }

  handleOpenRoomList = () =>{
    this.setState({
      isRoomListOpen : !this.state.isRoomListOpen
    })
  }

  handleOpenTaskList = () =>{
    this.setState({
      isTaskListOpen:!this.state.isTaskListOpen
    })
  }

  handleOpenPriorityList = () =>{
    this.setState({
      isPriorityOpen:!this.state.isPriorityOpen
    })
  }

  getProjectTaskListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
 
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProjectTaskListCallId = requestMsg.messageId;
  
    const isRoomSelected = this.state.roomList
      .filter((room) => this.state.isRoomSelected.includes(room.id))
      .map((room) => `room_name[]=${encodeURIComponent(room.attributes.room_name)}`)
      .join("&");

    const isTaskListSelectedFilter = this.state.taskList
      .filter((task) => this.state.isTaskListSelectedFilter.includes(task.id)) 
      .map((task) => `task_list_name[]=${encodeURIComponent(task.attributes.name)}`)
      .join("&");
  
    const isPriorityList = this.state.isPriorityList
      .filter((priority) => this.state.isPrioritySelected.includes(priority.id))
      .map((priority) => {
        const mappedPriority =
          priority.Priority_name === "Mid" ? "medium" : priority.Priority_name.toLowerCase();
        return `priority[]=${encodeURIComponent(mappedPriority)}`;
      })
      .join("&");
  
    const queryParams = [isRoomSelected, isTaskListSelectedFilter, isPriorityList]
      .filter((param) => param)
      .join("&");
  
    const endpoint = `${configJSON.GetProjectTaskListEndPoint}/${this.state.projectId}/kanban_board?${queryParams}`;
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMsg.id, requestMsg);
  
    return true;
  };

  handleProjectTaskListResponse = (responseJson: any) => {
    const searchTextLower = this.state.searchText.toLowerCase();   
    const filteredKanbanData = responseJson.kanban_board
      .filter((room: any) => 
        room.room_name.toLowerCase().includes(searchTextLower) || 
        room.task_lists_by_status.some((statusObj: any) =>
          statusObj.task_lists.some((taskList: any) =>
            taskList.name.toLowerCase().includes(searchTextLower)
          )
        )
      )
      .map((room: any) => {
        const filteredTaskListsByStatus = room.task_lists_by_status.map((statusObj: any) => {
          const filteredTaskLists = statusObj.task_lists.filter((taskList: any) =>
            taskList.name.toLowerCase().includes(searchTextLower)
          );
          return {
            ...statusObj,
            task_lists: filteredTaskLists
          };
        });
  
        return {
          ...room,
          task_lists_by_status: filteredTaskListsByStatus
        };
      });
  
    this.setState({
      projectName: responseJson.project_name,
      kanbanData: filteredKanbanData
    });
  };

 filterTasksByStatus = (status: string) => {
  const { kanbanData } = this.state;
    const normalizedStatus = status.trim().toLowerCase();
  
    const statusMapping: { [key: string]: string } = {
      "to do": "to_do",
      "in progress": "in_progress",
      "completed": "complete",
      "blocked": "deleted",
    };
  
    const backendStatus = statusMapping[normalizedStatus];

    return kanbanData.flatMap((room: any) =>
      room?.task_lists_by_status
        ?.filter((list: any) => list.status === backendStatus)
        ?.flatMap((list: any) =>
          list.task_lists.map((task: any) => ({
            taskId:task.id,
            roomId:room.room_id,
            roomName: room.room_name,
            taskName: task.name,
            priority: task.priority,
            checklist: `${task.tasks.length}/5`,
            assignee_name:task.assignee_name
          })
        )
        )
    );
  };

   getPriorityColor = (priority: string, type: string): string => {
    const colorMap: { [key: string]: { [key: string]: string } } = {
      text: {
        low: "#2563EB",
        medium: "#D97706",
        high: "#DC2626",
      },
      background: {
        low: "#DBEAFE",
        medium: "#FEF3C7",
        high: "#FEE2E2",
      },
    };
  
    return colorMap[type]?.[priority] || "";
  };

handleClearAllFilter = () =>{
  this.setState({
    isPrioritySelected:[],
    isRoomSelected:[],
    isTaskListSelectedFilter:[],
  })
  this.getProjectTaskListApi()
}

togglePriorityDropdown = (id: string) => {
  this.setState((prevState) => ({
    priorityDropdownOpen: {
      ...prevState.priorityDropdownOpen,
      [id]: !prevState.priorityDropdownOpen[id], // TypeScript now understands this is valid
    },
  }));
};



handlePriorityChange = async(id:string, roomId:string, newPriority:string) => {
  this.setState((prevState) => ({
    priorityDropdownOpen: {
      ...prevState.priorityDropdownOpen,
      [id]: false,
    },
  }));

  const token = await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,};

  const body = {
      "room_id":roomId,
      "task_list_id": id,
      "priority": newPriority.toLowerCase()
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.changeTaskListStatusCallId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.patchChangeStatusEndPoint}/${this.state.projectId}/update_priority`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.httpPatchMethod}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
};

handleTaskListDetailCard = (id:any,roomId:any) =>{
  this.setState((prev) => ({
    isTaskListDetailOpen: !prev.isTaskListDetailOpen,
    selectedTaskListID:id,
    currentRoomIDValue:roomId
  }));
  this.handleSelectedTaskList(id)
  this.handleCommentListAPIs()
  this.handleAttachmentList()
}

handleRefactorData =()=>{
     this.state.kanbanData?.map((val:any)=>{
        if(val?.room_id === this.state.currentRoomIDValue ){
             return val
        }
     })
}
 handleMenuClickDetail = (event:any) => {
  this.setState({
    anchorElStatus:event.currentTarget
  })
};

 handleMenuItemClickDetail = (status:string) => {
  this.setState({
    anchorElStatus:null,
    selectedStatus:status
  })
};
handleClose = () => {
  this.setState({
    anchorElStatus:null
  })
};

renderTaskListResponse=(value:any)=>{
  const responseValue = value.data?.data;

  this.setState({
    taskListValues:responseValue ? responseValue : []
  })
}

handleSelectedTaskList=async(idValue:any)=>{
  const token =  await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.taskListsValueId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.kanboardTaskListAPIs}/${idValue}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );

  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.validationApiMethodType}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}

getStatusBackgroundColorDetail = (status:string) => {
  switch (status) {
    case "To Do":
      return "#3B82F6";
    case "In Progress":
      return "#FBBF24";
    case "Completed":
      return "#34D399";
    case "Blocked":
      return "#F87171";
    case "Archived":
      return "#64748B";
    default:
      return "transparent";
  }
};

 handlePriorityClick = (event:any) => {
  this.setState({
    anchorElPriority:event.currentTarget
  })
};

 handlePriorityItemClick = (priority:string) => {
  this.setState({
    anchorElPriority:null,
    selectedPriority:priority
  })};

 handlePriorityClose = () => {
  this.setState({
    anchorElPriority:null
  })
};
 getPriorityBackgroundColor = (priority:string) => {
  switch (priority) {
    case "Low":
      return "#DBEAFE";
    case "Mid":
      return "#FEF3C7";
    case "High":
      return "#FEE2E2";
    default:
      return "transparent";
  }
};

getPriorityColorStatus = (priority:string) => {
  switch (priority) {
    case "Low":
      return "#2563EB";
    case "Mid":
      return "#D97706";
    case "High":
      return "#DC2626";
    default:
      return "transparent";
  }
};

truncateText(text:any) {
  if (text.length > 25) {
    return text.slice(0, 25) + '...';
  }
  return text;
} 
  
toggleItem = (index:any) => {
  if(this.state.toggleID === index){
    this.setState({
      toggleID:''
    })
  }
  else{
    this.setState({ 
      toggleID:index
    });
  }
};

handleChangeTaskListSelect = (id: string, roomId: string) => {
  this.setState((prevState: ComponentState) => {
    const isAlreadySelected = prevState.isTaskListArrayValue.includes(id);

    const updatedProjectTaskList = isAlreadySelected
      ? prevState.isTaskListArrayValue.filter((projectId:any) => projectId !== id)
      : [...prevState.isTaskListArrayValue, id];

    // Update roomDataSelected array
    const updatedRoomDataSelected = isAlreadySelected
      ? prevState.roomDataSelected.filter((data:any) => data.roomId !== roomId)
      : [...prevState.roomDataSelected, { id, roomId }];

    return {
      isTaskListArrayValue: updatedProjectTaskList,
    };
  });
};

handleCheckStatus =async(id:any)=>{
   const token = await getStorageData("token");
   const header = {
     "Content-Type": configJSON.validationApiContentType,
     token: token,};
 
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
 
   this.gettaskListIdValue = requestMessage.messageId;
   
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.kanbanBoardPutAPIs}/${id}/mark_task_complete`);
 
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header) );
 
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.httpsPutMethod}`
   );
   runEngine.sendMessage(requestMessage.id, requestMessage
   );

}

toggleShowAllTasks = () => {
  this.setState(prevState => ({
    showAll: !prevState.showAll,
    showAttachments:false,
    showComment:false
  }));
}

toggleShowComments=()=>{
  this.setState(prevState => ({
    showAll:false,
    showAttachments:false,
    showComment: !prevState.showComment,
  }));
}

toggleShowAttachment=()=>{
  this.setState((prevState)=>({
    showAll:false,
    showComment:false,
    showAttachments:!prevState.showAttachments
  }))
}

handleTypeComment =(value:any)=>{
  this.setState({
    commenttext:value
  })
}

handleCommentListAPIs=async()=>{
  const token = await getStorageData("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllCommentListValueId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getCommentListAPIs}${this.state.selectedTaskListID}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.validationApiMethodType}`
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}

handleAllComments=(value:any)=>{
   const outPutValue  =value.data?.data;
   this.setState({
    commentListAll:outPutValue ? outPutValue : []
   })
}

handlePopUpOpen=()=>{

}

postCommentAPIs=async()=>{
  const token = await getStorageData("token");

  const payload ={
    "project_tasklist_id":this.state.selectedTaskListID,
    "tasklist_comment":{
      "comment":`${this.state.commenttext}`
    }
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getHitPostCommentApis = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.commentsPostAPis}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.httpPostMethod}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );
   
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
  
}

handleAttachmentListStore=(value:any)=>{
  const dataStore = value.data?.data;
  this.setState({
    attachmentList:dataStore ? dataStore : []
  })
}


formatDateResponse = (dateString:any) => {
  const date = new Date(dateString);
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  return `${formattedDate} ${formattedTime}`;
};

handleAttachmentList=async()=>{
  const token = await getStorageData("token");

  const payload ={
    "project_tasklist_id":this.state.selectedTaskListID,
    "tasklist_comment":{
      "comment":`${this.state.commenttext}`
    }
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAttachmentListValue = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.attachmentListValues}/${this.state.selectedTaskListID}`);


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `${configJSON.validationApiMethodType}`
  );
   
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
}

}


// Customizable Area End
