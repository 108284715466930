import React from "react";

import {
  Container,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start

  styled,
  Box,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { AddCircleOutline, Delete, Edit ,ExpandMore } from '@material-ui/icons';
import { calendar } from "./assets";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@mui/material/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { backButton ,download_icon} from "./assets";



// Customizable Area End

import Proposalgeneration2Controller, {
  Props,
  configJSON,
  ProposalType
} from "./Proposalgeneration2Controller";

const theme = createTheme({
  palette: {
      primary: {
          main: "#9b59b6",
          contrastText: "#fff",
      },
      secondary: {
          main: "#f3f4f6",
      },
  },
});
export default class ProjectProposalgeneration extends Proposalgeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { currentPage, totalPages, zoom } = this.state;

    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
        <MyProfileHeader navigation={this.props.navigation} tabName="project"></MyProfileHeader>
      
<Box className="mainWrapper">
  
<Box style={{width:"80%"}}>

<SubHeader >

    <Box sx={{ display: 'flex', alignItems: 'center' ,marginRight:"10px"}}>
        <Box>
            <img src={backButton} alt="back" />
        </Box>
        <Box style={{cursor:"pointer"}} onClick={this.backNavigation}>
            <Typography className="backButtonTypo">Back</Typography>
        </Box>
       
    </Box>
    <Box className="tabButton_Wrapper">
          <TabButton>Proposal Quote</TabButton>
          <TabButton>Invoice March</TabButton>

        </Box>
</SubHeader>

<Box className="pdfSection">
   <div className="pdf-viewer">
   <div style={{position:"relative"}}>
      <div   className="canvas-container">
      <canvas ref={this.canvasRef}></canvas>

   </div>
   </div>
   </div>
   <Box style={{
    position: "fixed",
    bottom: "12px",
    left: "40%",
    transform: "translateX(-60%)",
  }}>
   <div className="pdf-controls">
      <Typography className="toolbar">
         <span className="activePage"> {currentPage}</span> / {totalPages}
      </Typography>
      <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', margin: '16px' }} />
      <ActionButton data-test-id="handleZoomOut" className="buttons" style={{ margin: '5px' }} onClick={this.handleZoomOut}>-</ActionButton>
      <p className="activePage zoom_percenage" style={{margin:"10px "}}>{(zoom * 100).toFixed(0)}%</p>
      <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.handleZoomIn}>+</ActionButton>
      <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', margin: '16px' }} />

      <ActionButton data-test-id="download" className="buttons" onClick={this.handleDownload}>
      <img src={download_icon} alt="" width={'18px'} height={'18px'}  />
      </ActionButton>
   </div>
</Box>
</Box>
</Box>

<Box className="DetailSection">
        <Box className="acc_parent_wrapeer">
      <Accordionwrapper defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="span" className="acc_heading">Project Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box >
          <Typography className="acc_det_heder">Project Name: </Typography>
          <Typography className="acc_det_para">
             Grand Summit Estates: A Magnificent Residential Development
          </Typography>
          </Box>
          <Box >
          <Typography className="acc_det_heder">File Name:</Typography>
          <Typography className="acc_det_para">Proposal Quote</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Created On:</Typography>
          <Typography className="acc_det_para">Sept 22, 2024 (2days ago)</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Total Cost:</Typography>
          <Typography className="acc_det_para">£ 3,589,177.82</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Adjusted Cost:</Typography>
          <Typography className="acc_det_para">+ £5,000</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Address:</Typography>
          <Typography className="acc_det_para">123 Riverside Avenue, Cambridge, United Kingdom, CB1 1AB</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Plan Type:</Typography>
          <Typography className="acc_det_para">Milestone</Typography>
          </Box>
          <Box>
          <Typography className="acc_det_heder">Retention Percentage:</Typography>
          <Typography className="acc_det_para">5%</Typography>
          </Box>
        </AccordionDetails>
      </Accordionwrapper>
   
    </Box>
    <Box style={{background:"#FFFFFF"}}>
    <CustomGenerateQuoteButton>
        Send for Review
    </CustomGenerateQuoteButton>
    </Box>
</Box>
</Box>
        </MainWrapper>

        </ThemeProvider>

      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ActionButton = styled(Button)({
border:"none",
background:"transparent",
padding:"0px",
minWidth:"unset"
})

const CustomGenerateQuoteButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  bottom: 10,
  width: "320px",
   height: "44px",
  padding: "10px 16px", gap: "8px",
  borderRadius: "8px", background: "#237182",
  color: "#FFFFFF",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: "capitalize",
  marginLeft:"20px",
  "&:hover": {
    background: "#237182",
  },

}));
const Accordionwrapper = styled(Accordion)({
  " & .MuiAccordion-root":{
  height:"68vh",
 boxShadow:"none"

},
"& .MuiAccordionSummary-root":{
  padding:"16px 16px",

},
"& .MuiPaper-elevation1":{
  boxShadow:"none !important"
},
  "& .acc_heading":{
  color: '#0F172A',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '26px',
},
"& .MuiAccordionSummary-root.Mui-expanded":{
  border:"1px solid #E2E8F0 !important"
},
"& .MuiAccordion-rounded":{
  marginBottom:"99px"
},
"& .acc_det_heder":{
color:"#334155",
fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
},
"& .acc_det_para":{
color:"#334155",
fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
},
"& .MuiAccordionDetails-root":{
  flexDirection:"column",
  gap:"10px"
}
})

const SubHeader = styled(Box)({
  display: 'flex',  alignItems: 'center', width: '100%', 
  justifyContent:"start",
  padding:" 20px ",
  paddingBottom:0,
});
const TabButton = styled(Button)({
  padding: "8px 16px",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize:16,
  // textTransform: "none",
  background: "#D3E4E9",
  color: "#0F172A",
  fontWeight: 400,
  border:"1px solid #325962",

});
const MainWrapper = styled(Box)(({ theme }) => ({
"& .mainWrapper":{
  display:"flex",
  width:"100%",
  hight:"100%",
  backgroundColor: "rgb(240, 245, 247)",

},
"& .pdfsection":{
  margin:"20px ",
  marginBottom:"0px",
  padding:" 20px ",
  paddingBottom:"0px",
  borderTopLeftRadius: "8px", 
  borderTopRightRadius: "8px", 
  overflowY:"scroll",
  height:" calc(100vh - 195px)",
  position: 'relative',
  background:"#ffffff",
  overflowX: "hidden", 
  scrollbarWidth: "auto", 
  scrollbarColor:" #CBD5E1 #ffffff", 

   "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },


},


"& .DetailSection":{
  minWidth:"360px",
  width:"360",
  position:"relative",
  height:" calc(100vh - 135px)",
  overflowY:"scroll",
  overflowX: "hidden", 
  scrollbarWidth: "auto", 
  scrollbarColor:" #CBD5E1 #ffffff", 

   "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },
},
"& .backButtonTypo": {
  color: '#334155',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textAlign: 'left',
},
"& .tabButton_Wrapper":{
  display: 'flex', justifyContent: 'space-between', alignItems: 'center',  gap:"10px" 
},
"& .canvas": {
  flex: 1,
  position: 'relative',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
"& .pdfCanvas": {
  width: '90%',
  height: '90%',
  border: '1px solid #ccc',
},
'& .pdf-controls': {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
  background: '#F8FAFC',
  boxShadow: '0px 8px 32px 0px #0000000F',
  padding: '0px 20px',
  textAlign: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '45px'
},
'& .pdf-viewer': {
        position: 'relative',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
    },
'& .canvas-container:': {
  overflow: 'auto',
  width: '100%',
},
"& .zoom_percenage":{
  border:"1px solid #CBD5E1",
  borderRadius:"8px",
  background:"#FFFFFF",
  padding:"10px 8px"

}

}))
// Customizable Area End
