// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import {
  setStorageData,
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import storage from "../../../framework/src/StorageProvider";
const { NavigationActions, StackActions } = require("react-navigation");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface FormValues {
  projectName: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  developerEmail: string;
  developerFullName: string;
  firstName: string;
  projectDescription: string;
  lastName: string;
  postCode: string;
  city: string;
  email: string;
  streetName: string;
  phoneNumber: string;
  startDate: Date | null;
  endDate: Date | null;
  country: string;
  state: string;
  countryCode: string;
  draft:boolean,
  
}
interface PortfolioProjects {
  id: string;
  start_date:string;
  title: string;
  location: string;
  draft: boolean;
  status:string;
  cost: string; 
  files_uploaded: boolean;
  description?: string;
  counterylocation?: string;
  Submitted?: string;


}

interface S {
  isModalVisible: boolean;
  filterValue: string;
  isDeleteModalOpen: boolean;
  token: string;
  searchInputText: string;
  deleteProjectID: string;
  isFilterMenuOpen: Element | null;
  isDialogOpen: boolean;
  loading: boolean;
  pageNumber: number | null;
  anchorEl: any;
  cost: string;
  title: string;
  open: false;
  location: string;
  projects: ({
    start_date: string | number | Date;
    startDate: string | number | Date;
    id: string;
    title: string;
    location: string;
    draft: boolean;
    status: string;
    cost: string;
    files_uploaded: boolean;
  } | PortfolioProjects)[];
  activeTab: number;
  specialistCostPlan: boolean;
  sortType: { label: string; value: string }[];
  sortTypeValue: string;
  formValues: FormValues;
  isEditing: { [key: string]: boolean };
  tempName: { [key: string]: string };
  errors: { [key: string]: string | null };
  selectedProjectId:string | null;
  project_id :string;
  portfolioProjects : PortfolioProjects[];
  openDialogLeave:boolean,
  openDialog:boolean,
  path: string;
  handleDuplicateProject: (() => void) | null;
  userRole:string,
  searchText: string,
  messageToggle:boolean,
  tostifymessage:string,
  status:string,
  DeleteModalOpen:boolean,
  menuAnchorSort: HTMLElement | null,
  sortTypeText: string,



}

interface SS {
  id: any;
}

export default class SpecialistCostPlanProjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetProjectsListCallId: string | null = "";
  apiDeleteProjectsListCallId: string | null = "";
  apiDeleteProjectByIdCallId: string | null = "";
  apiDuplicateProjectByIdCallId: string | null = "";
  apiGetProjectByIdCallId: string | null = "";
  apiGetProfileCallId: string | null = "";

  constructor(props: Props) {
    
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isModalVisible: false,
      filterValue: "All Projects",
      isDeleteModalOpen: false,
      token: "",
      searchInputText: "",
      deleteProjectID: "",
      isFilterMenuOpen: null,
      isDialogOpen: false,
      loading: false,
      pageNumber: 1,
      anchorEl: null,
      cost: "",
      title: "",
      open: false,
      location: "",
      activeTab: 0,
      projects: [],
      specialistCostPlan: false,
      sortType: [
        { label: "Newest First", value: "date_asc" },
        { label: "Oldest First", value: "date_desc" },
        { label: "A to Z (Project Name)", value: "A_Z" },
        { label: "Z to A (Project Name)", value: "Z_A" },
      ],
      sortTypeValue: "date_desc",
      formValues: {
        projectName: "",
        draft:false,
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: null,
        endDate: null,
        developerEmail: "",
        developerFullName: "",
        firstName: "",
        projectDescription: "",
        lastName: "",
        postCode: "",
        city: "",
        email: "",
        streetName: "",
        phoneNumber: "",
        country: "",
        state: "",
        countryCode: "+44",
      },
      isEditing: {},
      tempName: {},
      errors: {},
      selectedProjectId :null,
      project_id : "",
      portfolioProjects: [] as PortfolioProjects[],
      openDialogLeave:false, 
      openDialog:false,
      path: '',
      handleDuplicateProject:null,
      userRole:'',
      searchText: '',
      messageToggle:false,
      tostifymessage:"",
      status:"",
      DeleteModalOpen:false,
      menuAnchorSort: null,
      sortTypeText: 'Newest First',


    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
resolveGetProjectDetails: ((value?: unknown) => void) | null = null;





  handleSortByChange = (event: string, option: string) => {
    this.setState({ sortTypeValue: event, sortTypeText: option }, () => { });
    this.handleMenuClose()
};

 

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeTab: newValue });
  };

  handleClick = (project_id:any,event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetProjectsListCallId: {
          this.getProjectListHandleResponse(responseJson);
          break;
        }
      
      }
    }
    this.receive1(message);
    this.receive2(message);
    this.receive3(message);
  }
  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDeleteProjectByIdCallId) {
          if(responseJson.meta.message){
            this.setState({ messageToggle:true, tostifymessage:'Your project has been deleted successfully.', status:"success" }, ()=>{
              this.closeMessageBox();
            });
            this.getProjectDeleteByIdHandleResponse();
          }else{
            this.setState({ messageToggle:true, tostifymessage:'Something went wrong', status:"failed" }, ()=>{
              this.closeMessageBox();
            });
          }
        }
      }
    }
  }
  receive2=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDuplicateProjectByIdCallId) {
          if(responseJson.data.id){
            this.setState({ messageToggle:true, tostifymessage:'Your project has been duplicated successfully.', status:"success" }, ()=>{
              this.closeMessageBox();
            });
            this.getProjectDuplicateHandleResponse(responseJson);
          }else{
            this.setState({ messageToggle:true, tostifymessage:'Something went wrong', status:"failed" }, ()=>{
              this.closeMessageBox();
            });
          }
        }
      }
    }
  }
  receive3=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetProjectByIdCallId) {
          if (this.resolveGetProjectDetails) {
            this.resolveGetProjectDetails(); 
            this.resolveGetProjectDetails = null;
        }
        }
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getProjectsListData();
    document.addEventListener('mousedown', this.handleClickOutside);
    storage.remove('activeStep')
  }




closeMessageBox = () => {
  setTimeout(()=>{
   this.setState({messageToggle:false, tostifymessage:"", status:""})
  },2000);
}

handleClickOutside = (event: MouseEvent) => {
  if (
      this.state.selectedProjectId &&
      !(event.target as HTMLElement).closest('.menu') &&
      !(event.target as HTMLElement).closest('.rename-input')
  ) {
      this.setState({ selectedProjectId: null });
  }
};
 formatDate = (isoDate: string) => {
  if (!isoDate) return "N/A";
  const date = new Date(isoDate);
  date.setDate(date.getDate()); 
  return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
};

  getProjectListHandleResponse = (responseJson: any) => {    
    const projects = responseJson.data.map((project: any) => ({
      id: project.id,
      start_date: project.attributes.start_date,
      title: project.attributes.project_name,
      location: project.attributes.addressable?.city || '',
      draft :  project.attributes.draft,
      status: project.attributes.status,
      cost: project.attributes.cost || "N/A",
      files_uploaded : project.attributes.files_uploaded,
      created_at : project.attributes.addressable?.created_at,
      project_planid:project.attributes.project_plan_id,
      Submitted: this.formatDate(project.attributes.payment_admin[0]?.succeeded_date),
      Countrylocation: project.attributes.addressable?.country || '',
      Submittedbyfirstname: project.attributes.profile.first_name,
      Submittedbylastname: project.attributes.profile.last_name,




    }));
    this.setState({ projects, loading: false });
  };

  getProjectsListData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_profile/projects?plan_type=specialist_cost_plan'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearch(text: string) {
    this.getProjectsListData();
    this.setState({ searchInputText: text });
  }





  openFilterMenu() {
    this.setState({ isModalVisible: true });
  }

  deleteModal(projectID: string) {
    this.setState({ isDeleteModalOpen: true, deleteProjectID: projectID });
  }

  closeDeleteModal() {
    this.setState({ isDeleteModalOpen: false });
  }







 

 


  handleOpenDialog=()=>{
    this.setState({openDialog:true})
    }
    handleOpenDialogLeave = (path: string) => {
      const { projectName, projectDescription } = this.state.formValues; 
      if (!projectName || !projectDescription) {
          this.props.navigation.navigate(path);
      } else {    
          this.setState({ 
              path, 
              openDialogLeave: true 
          });
      }
  };
  
  
  
    handleCloseDialog=()=>{
      this.setState({openDialog:false,openDialogLeave:false,selectedProjectId:null})
  
    }

    openDuplicateDialog = (project_id:any) => {
      this.setState({
        openDialogLeave: true,
        handleDuplicateProject: () => this.handleDuplicateProject(project_id) 
      });
     
    }



  handleClose = () => {
    this.setState({ anchorEl: null});
  };
  toggleProjectMenu = (projectId: string) => {
    this.setState(prevState => ({
        selectedProjectId: prevState.selectedProjectId === projectId ? null : projectId
    }));
};






  


getPlanName = (planId: string | number | null) => {
  switch (planId) {
    case "1":
      return "automated_cost_plan";
    case "2":
      return 'manual_cost_plan';
    case "3":
      return "specialist_cost_plan";
    default:
      break;
  }
}
 
  handleDuplicateProject=async(project_id:any)=>{
    this.handleClose();
    this.handleCloseDialog();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDuplicateProjectByIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.duplicateProjectByIdAPIEndPoint}/${project_id}/duplicate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.duplicateAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  handleDeleteProject=()=>{
    this.handleClose();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProjectByIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProjectByIdAPIEndPoint}/${this.state.project_id}`

      
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  getProjectDeleteByIdHandleResponse = () => {
    const project_id = this.state.project_id;
    const updatedProjects = this.state.projects.filter(
      project => project.id.toString() !== project_id.toString()
    );
    this.setState({ projects: updatedProjects }, () => {  
    });
  }
  
  getProjectDuplicateHandleResponse=(responseJson:any) =>{
    const newProject: PortfolioProjects = {
        id: responseJson.data.id,
        start_date : responseJson.data.attributes.start_date,
        title: responseJson.data.attributes.project_name,
        location: responseJson.data.attributes.addressable?.city || "",
        draft: responseJson.data.attributes.draft,
        status : responseJson.data.attributes.status,
        cost: responseJson.data.attributes.cost || "N/A", 
        files_uploaded: false,
        description: responseJson.data.attributes.description || "",
    };

  
    this.setState(prevState => {
        const updatedProjects = prevState.projects.filter(
            project => project.id !== newProject.id
        );

       
        return { projects: [...updatedProjects, newProject] };
    });

  }
  
  Openconfirmmodel= (project_id:any)=>{
  this.setState({  DeleteModalOpen:true });
  this.setState({project_id:project_id})
  this.setState({ selectedProjectId: null,  });
  }
  DeleteModelclose=()=>{
    this.setState({DeleteModalOpen:false})
  }
  DeleteConfirm(){
    this.handleDeleteProject()
    this.DeleteModelclose()
  }
  openSortDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    
    this.setState({
        menuAnchorSort: event.currentTarget,
    })
  
  }
  handleMenuClose = () => {
    this.setState({ menuAnchorSort: null });
  };
  handleCreateNew = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "CreateEditProjectPortfolio"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), 3);
    this.send(message);
  }
  projectClick = (projectId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "SpecialistCostPlan"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), projectId);
    this.send(message);
  }
}
// Customizable Area End
