import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string,
  password: string,
  confirmPassword: string,
  agreeToTerms: boolean,
  validationRules:any,
  validations: {
    [key: string]: boolean,
    emailValid: boolean,
    hasUpper: boolean,
    hasLower: boolean,
    hasSpecialChar: boolean,
    hasNumber: boolean,
    minLength: boolean,
    passwordsMatch: boolean,
    termsAccepted: boolean,
  },
  errors: {
    email: string,
    password: string,
    confirmPassword: string,
    terms: string,
    account:string,
    msg:string
  },
  isDisabled: boolean;
  accountError:string; 
  openModal:boolean;
  openTermsCondModal:boolean
  termsdata:Array<object>
  visiblePassword:boolean;
  visibleConfirmPass:boolean;
  originalPassword:string;
  originalPassword1:string;
  event:any;
  asterisk:string;
  asterisk1:string;
  showPassword:boolean
  showPassword1:boolean
  showSecondPart:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailSignUpApiCallId :string =''
  getTokenCheckExpiryId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: '',
      password: '',
      confirmPassword: '',
      agreeToTerms: false,
      validations: {
        emailValid: false,
        hasUpper: false,
        hasLower: false,
        hasSpecialChar: false,
        hasNumber: false,
        minLength: false,
        passwordsMatch: false,
        termsAccepted: false,
      },
      errors: {
        email: '',
        password: '',
        confirmPassword: '',
        terms: '',
        account: '',
        msg: ''
      },
      isDisabled: false,
      accountError:'',
      openModal:false,
      openTermsCondModal:false,
      termsdata:[], 
      visiblePassword:true,
      visibleConfirmPass:true,
      originalPassword:'',
      originalPassword1:'',
      event:"",      
      asterisk:"",
      asterisk1:"",
      showPassword:true,
      showPassword1:true,
      showSecondPart:false,
      validationRules:[]
     
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.emailSignUpApiCallId) {
        if(responseJson.data && responseJson.data.id) {   
         localStorage.setItem("email",responseJson.data.attributes.email);
          const id = responseJson.data.id;
          const token = responseJson.meta.token;
  
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          msg.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'Emailnotifications2'
          );
          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          )
          this.send(msg);       
          return;
        }
        this.handleErrorSignup(responseJson);
      }
      if (apiRequestCallId === this.getTokenCheckExpiryId) {
        if (responseJson) {
           if(responseJson.isValidToken){
            this.props.navigation.navigate("CustomisableUserProfiles");
           }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount () {
    this.setState((prev:any)=>{
      return{
        ...prev.state,
        validationRules :[
          {
            condition: this.state.validations.hasUpper && this.state.validations.hasLower,
            text: configJSON.textOne1,
          },
          {
            condition: this.state.validations.hasSpecialChar,
            text: configJSON.textThree3,
          },
          {
            condition: this.state.validations.hasNumber,
            text: configJSON.textFour4,
          },
          {
            condition: this.state.validations.minLength,
            text: configJSON.textFive5,
          },
        ]
      }
    })
  super.componentDidMount();
 }

 getTokenCheckExpiryApiCall = () => {
  const token1 = localStorage.getItem("token");
  const header = {
    "Content-Type": "application/json"
  };

  const payload = {
    "token": token1
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getTokenCheckExpiryId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_login/logins/check_token`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

 

  handleErrorSignup=(responseJson:any)=>{
    const emailValid = false;
    if(responseJson.errors.email){
    this.setState({
      validations: { ...this.state.validations, emailValid },
      errors: { ...this.state.errors, email: responseJson.errors.email[0] }
    });
  } else {
    this.setState({
      validations: { ...this.state.validations, emailValid },
      errors: { ...this.state.errors, password: responseJson.errors.password[0] }
    });
  }
  }

    validateConditions = () => {
    const validEmail = configJSON.emailValidText.test(this.state.email)  
    const hasUpper = configJSON.hasUpper.test(this.state.originalPassword);
    const hasLower = configJSON.hasLower.test(this.state.originalPassword);
    const hasSpecialChar = configJSON.hasSpecialChar.test(this.state.originalPassword);
    const hasNumber = configJSON.hasNumber.test(this.state.originalPassword);
    const minLength = this.state.originalPassword.length >= 8;
    const matchedData = this.state.originalPassword1 === this.state.originalPassword;
      if(validEmail && hasUpper && hasLower && hasNumber && hasSpecialChar && minLength && matchedData && this.state.agreeToTerms){
       return true
      }
      else {
       return  false
      }
    };

    validateEmail = (email: string) => {
      const emailValid = configJSON.emailValidText.test(email);


      const error = emailValid ? '' : 'Please enter valid  email address.';
    
      this.setState({
        validations: { ...this.state.validations, emailValid },
        errors: { ...this.state.errors, email: error },
      });
    };


    handleContinue = (email:string) => {
      const emailValid = configJSON.emailValidText.test(email);
      const error = emailValid ? '' : 'Please enter valid  email address.';
      if (this.state.email) {
        this.setState({ showSecondPart: true });
      } else {
        this.setState({
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error },
        });
      }
    };


    handleChangePassword = (e:any) => {
      const { value } = e.target;
      const { originalPassword } = this.state;
      const eventInput = e.nativeEvent as InputEvent;
      const inputEle = e.target as HTMLInputElement;
      const cursorPosition = inputEle.selectionStart || 0;
    
      if (!eventInput || !eventInput.inputType || value === " ") {
        return; }

      let origin ;
    
      if (eventInput.inputType === 'insertText' && eventInput.data) {
        const data = eventInput.data;
        const originalArray = originalPassword.split('');
        originalArray.splice(cursorPosition-1,0,data); 
        origin = originalArray.join('');
        this.setState(
          {
            originalPassword: origin,
            asterisk: "*".repeat(origin.length),
            password: value,
          },
          () => {
            const latestCursor = cursorPosition-1 + data.length;
            inputEle.setSelectionRange(latestCursor, latestCursor);
          }
        );
          }
          else if(!value){
            origin=value;
           }
           else{
             const originalArray = originalPassword.split('');
             originalArray.splice(cursorPosition, 1); 
             origin = originalArray.join('');
           }
           this.setState({
            originalPassword: origin,
              asterisk: "*".repeat(origin.length),
          });

    };
    
  validatePassword = () => {
    const hasUpper = configJSON.hasUpper.test(this.state.originalPassword);
    const hasLower = configJSON.hasLower.test(this.state.originalPassword);
    const hasSpecialChar = configJSON.hasSpecialChar.test(this.state.originalPassword);
    const hasNumber = configJSON.hasNumber.test(this.state.originalPassword);
    const minLength = this.state.originalPassword.length >= 8;
    const passwordsMatch = this.state.originalPassword1 === '' || this.state.originalPassword === this.state.originalPassword1;
  
    const passwordError = hasUpper && hasLower && hasSpecialChar && hasNumber && minLength ? '' : 'Password is not valid';

    this.setState((prev:any)=>{
      return {
        ...prev.state,
        validations: {
          ...this.state.validations,
          hasUpper,
          hasLower,
          hasSpecialChar,
          hasNumber,
          minLength,
          passwordsMatch,
        },
        errors: {
          ...this.state.errors,
          password: passwordError,
        },
        validationRules:[
          {
            condition: hasUpper && hasLower,
            text: configJSON.textOne1,
          },
          {
            condition: hasSpecialChar,
            text: configJSON.textThree3,
          },
          {
            condition:hasNumber,
            text: configJSON.textFour4,
          },
          {
            condition: minLength,
            text: configJSON.textFive5,
          },
        ]
      }
    })
    
  
   
  };

  handleChangePassword1 =(e:any)=>{
    const { value } = e.target;
    const { originalPassword1 } = this.state;
    const inputEventHandle = e.nativeEvent as InputEvent;
    const inputData = e.target as HTMLInputElement;
    const cursorPosition = inputData.selectionStart || 0;
  
    if (!inputEventHandle || !inputEventHandle.inputType || value === " ") {
      return; }

    let origi ;
  
    if (inputEventHandle.inputType === 'insertText' && inputEventHandle.data) {
      const data = inputEventHandle.data;
      const originalArray = originalPassword1.split('');
      originalArray.splice(cursorPosition-1,0,data); 
      origi = originalArray.join('');
      this.setState(
        {
          originalPassword1: origi,
          asterisk1: "*".repeat(origi.length),
          confirmPassword: value,
        },
        () => {
          const endPosition = cursorPosition-1 + data.length;
          inputData.setSelectionRange(endPosition, endPosition);
        }
      );
        }
        else if(!value){
          origi=value;
         }
         else{
           const originalArray = originalPassword1.split('');
           originalArray.splice(cursorPosition, 1); 
           origi = originalArray.join('');
         }
         this.setState({
          originalPassword1: origi,
            asterisk1: "*".repeat(origi.length),
        });
  }
    
   validateConfirmPassword = () => {
      const passwordsMatch = this.state.originalPassword === this.state.originalPassword1;
      const error = passwordsMatch ? '' : "Passwords don't match";

      this.setState({
        validations: { ...this.state.validations, passwordsMatch },
        errors: { ...this.state.errors, confirmPassword: error },
      });
    };
    
    validateTerms = (agreeToTerms: boolean) => {
      this.setState({
        validations: { ...this.state.validations, termsAccepted: agreeToTerms },
        errors: { ...this.state.errors, terms: agreeToTerms ? '' : 'You must agree to the terms and conditions' },

      });
    }
    

    handleInputChange = (e:any) => {
      const { name, value, checked, type } = e.target;
      const newValue = value.replace(/\s+/g, '');

      const fieldValue = type === 'checkbox' ? checked : newValue;
  
      this.setState({
          [name]: fieldValue,
          errors: { ...this.state.errors, [name]: '' }
        } as unknown as Pick<S, keyof S>
      );
    };

    handleBlur = (e: any) => {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;

    
      switch (name) {
        case 'email':
          this.validateEmail(fieldValue as string);
          break;
        case 'passwordtext':
          this.validatePassword();
          break;  
        case 'originalPassword1':
          this.validateConfirmPassword();
          break;     
        default:
          break;
      }
    };
  
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    const header = {
      "Content-Type": "application/json",
    };
  
    const attrs = {
      "email": this.state.email,
      "password": this.state.originalPassword,
      "password_confirmation":this.state.originalPassword1,
      "tnc_status": this.state.agreeToTerms,
      "role_id":0
    };
  
    const httpBody = {
      "data": {
        "attributes": attrs,
      },
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailSignUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSignUp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }; 
  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    })); 
    
  }

  toggleVisibility1 = () => {
    this.setState(prevState => ({
      showPassword1: !prevState.showPassword1
    }));
  }

  handleOpenModal = () => this.setState({ openModal: true });
  handleCloseModal = () => this.setState({ openModal: false });
  handleOpenTermsCondModal = () => this.setState({ openTermsCondModal: true });
  handleCloseTermsCondsModal = () => this.setState({ openTermsCondModal: false });
    
  handleLoginNavigate=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg);
  }
  // Customizable Area End
}
