Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "proposalgeneration";
exports.labelBodyText = "proposalgeneration Body";

exports.btnExampleTitle = "CLICK ME";
exports.proposalEndpoint = "bx_block_proposal_generation/proposal_generations";
exports.deleteMethod = "DELETE";
exports.updateMethod = "PUT";
exports.formDataContent = "application/json";
exports.postMethod= "POST";
exports.shareProposalEndpoint = "bx_block_proposal_generation/proposal_generations/share_proposal";
exports.mileStoneOptionsApi="bx_block_profile/payment_plans";
exports.nextBtnClickProjectId="bx_block_profile/select_payment_plan";
exports.roomCostListAPI="bx_block_profile/room_list_for_milestones";
exports.savePlanHitAPI="bx_block_profile/save_payment_plans";

exports.getContentType = "application/json";
exports.getProjectByIdAPIEndPoint = "bx_block_profile/projects";
exports.getTaskRoomListApiEndPoint = "bx_block_profile/project_rooms/project_rooms_cost_list"
exports.getTaskListAPIEndPoint = "bx_block_tasks/task_lists";
exports.getTaskByIdAPIEndPoint = "bx_block_tasks/task_lists";
exports.getAPiEndMethod = "GET";

// Customizable Area End