// Customisable Area Start
import React from "react";
import clsx from "clsx";
import CreateEditProjectPortfolioController, {
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
  Box,
  Button,
  createTheme,
  Grid,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos, PersonAddOutlined } from "@material-ui/icons";
import { buildNewIcon, extendIcon, refurbishIcon, remodelIcon } from "./assets";
import Loader from "../../../components/src/Loader";
import AddAndViewMember from "./AddAndViewMember.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const StyledBox = styled(Box)({
  padding: "2rem 2.5rem", height:'100vh',
  textAlign: "center",

  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    height:'38px',
    marginBottom: "3.5rem",
  },
  "& .backButton": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    width:'353px',
    fontWeight: "600",
    "& p": {
      fontSize: "0.875rem",
      color: "#334155",
      fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#334155",
    },
  },
  "& .titleText": {
    color: "#0F172A",
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
  },
  "& .buttonsBlock": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  "& .addMemberBtn": {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
  },
  "& .addMemberIcon": {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  "& .addMemberText": {
    fontWeight: 600,
    color: "#237182",
  },
  "& .saveDraftBtn": {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "1.5rem",
    textTransform: "none",
    fontFamily:'Poppins',
  },
  "& .subTitle": {
    fontSize: "1.125rem",
    color: "#475569",
  },
  "& .titleBox":{
      display:'flex',
      flexDirection:'column',
      gap:'12px',
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: 0,
  },
  "& .planItem": {
    padding: "2rem 1.5rem",
    background: "#FFF",
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    height: "calc(100% - 4rem)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .planTitle": {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      color: "#1E293B",
      margin: "1.5rem 0 1rem",
    },
    "& .planDesc": {
      color: "#475569",
      marginBottom: "1rem",
    },
  },
  "& .selectedScopeItem": {
    border: "1px solid #237182",
  },
  "& .selectScopeBtn": {
    width: "100%",
    background: "#237182",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    "&:disabled": {
      background: "none",
      color: "#64748B",
    },
  },
});
const scopes = [
  {
    id: 1,
    icon: buildNewIcon,
    title: "Build New",
    description: "Building a new construction and fitting out",
  },
  {
    id: 2,
    icon: extendIcon,
    title: "Extend",
    description: "Adding on to existing structure and fitting out",
  },
  {
    id: 3,
    icon: remodelIcon,
    title: "Remodel",
    description: "Changing the layout ofa structure or room and fitting out",
  },
  {
    id: 4,
    icon: refurbishIcon,
    title: "Refurbish",
    description:
      "Replacing the finishes and fittings in their existing locations",
  },
];
// Customizable Area End

export default class ProjectScope extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      <ThemeProvider theme={theme}>
         <Loader loading={this.state.loading} />
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <StyledBox data-test-id = "styleBox">
            <Box className="pageTitle">
              <Box className="backButton" data-test-id="backBtn" onClick={()=>this.goBack()}>
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
               <Box className="titleBox">
               <Typography className="titleText">
                What are the scopes of your project?
              </Typography>
              <Typography className="subTitle">
              You can select more than one
            </Typography>
               </Box>
              <Box className="buttonsBlock">
              <Box style={{
                              display: "flex",
                              gap: "0.5rem",
                              cursor: "pointer",
                                   }}>
                  <Typography  onClick={this.handleOpenViewAndAddMemberDiload} style={{
                                    fontWeight: 600,
                                    color: "#237182",
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Poppins'
                                             }}>
                                    View & Add Member
                                 </Typography>
                </Box>
                <Button className="saveDraftBtn">Save Draft</Button>
                <Button
                  className="selectScopeBtn"
                  style={{ width: "auto" }}
                  disabled={this.state.multipleScopesSelected.length === 0}
                  data-test-id="next-btn"
                  onClick={()=>this.navigateToShells(this.state.projectId)}
                >
                  Next
                </Button>
              </Box>
            </Box>
           {this.state.scopeCategories.length > 1 ?
                       <Grid container spacing={2} xs={12}>
                       {this.state.scopeCategories.map((plan:any,index:Number) => (
                         <Grid item key={plan.id} sm={3} xs={12}>
                           <Box
                             className={clsx(
                               "planItem",
                              plan.selected && "selectedScopeItem"
                             )}
                             onClick={() => this.handleScopeClick(plan.id,plan.selected)}
                             data-test-id={`plan_${index}`}
                           >
                             <Box>
                               <img src={plan.icon} />
                               <Typography className="planTitle">
                                 {plan.title}
                               </Typography>
                               <Typography className="planDesc">
                                 {plan.description}
                               </Typography>
                             </Box>
                           </Box>
                         </Grid>
                       ))}
                     </Grid>:''}
          </StyledBox>
          <AddAndViewMember open={this.state.openAddMemberDialog} close={this.handleCloseViewAndAddMemberDiload}  projectId={this.state.projectId} planId={this.state.planId} navigation={undefined} id={""}/>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}
// Customisable Area End
