// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export interface Props {
  navigation: any;
  id: string;
  membersData: Member[];
  selectedOptions: [],
  onSelectionChange?: (selectedOptions: any[]) => void;
  memberError:string;
  onMemberErrorClear: () => void;
 
}


interface Member {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  }
  
  
  // Define the props for CustomSelect
  interface CustomSelectProps {

  
  }
  
interface S {
  options: Array<{ value: number; label: string; email:string }>;
  count:any; 
}

interface SS {
  id: any;
}

export default class AddMembersModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        options: this.getOptions(props.membersData),
        count:null,
   
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  getOptions = (membersData: Member[]) => {
    return membersData.map((member) => {
      const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return {
        value: member.id,
        label: `${capitalize(member.first_name)} ${capitalize(member.last_name)}`,
        email: `${member.email}`,
      };
    });
  };
  
async componentDidUpdate(prevProps: Props) {
if (prevProps.membersData !== this.props.membersData) {
  this.setState({
    options: this.getOptions(this.props.membersData),
  });
}
}

filterOption = (option: any, inputValue: string) => {
    const { label, email } = option.data;
    const input = inputValue.toLowerCase();
    return (
      label.toLowerCase().includes(input) || email.toLowerCase().includes(input)
    );
  };

  handleChange = (selectedOptions: any) => {
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(selectedOptions || []);
      this.props.onMemberErrorClear();
    }
  };

  onInputChange = (inputValue: string) => {
    const { options } = this.state;
    const filteredOptions = options.filter((option) => {
      const { label, email } = option;
      const input = inputValue.toLowerCase();
      return (
        label.toLowerCase().includes(input) ||
        email.toLowerCase().includes(input)
      );
    });

    this.setState({
      count: filteredOptions.length,
    });
  };

  noOptionsMessage = () => {
    return this.props.membersData.length === 0
      ? "No members present"
      : "No members found";
  };


}

// Customizable Area End
