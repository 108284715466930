// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import * as React from "react";


const { PDFDocumentProxy } = require("pdfjs-dist");
const pdfjsLib = require("pdfjs-dist");
if (!pdfjsLib.GlobalWorkerOptions.workerSrc) {
  const pdfjsVersion = require('pdfjs-dist/package.json').version;
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  uploadedFile: any;
  uploadFileUrl: string;
  fileName: string;
  numPages: number;
  pageNumber: number;
  scale: number;
  zoomLevel: number;
  tool: "line" | "rectangle" | "arrow" | "hand" | null;
  lines: {
    id: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
    length: number;
  }[];
  rectangles: {
    id: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
    area: number;
    width: number;
    height: number;
  }[];
  dragging: boolean;
  startPoint: { x: number; y: number } | null;
  pixels: number;
  realWorldDistance: number;
  lineWidth: number;
  lineColor: string;
  rectangleBorderColor: string;
  rectangleBackgroundColor: string;
  isMounted:boolean;

    unit: string;
  endpoint1: { x: number; y: number };
  endpoint2: { x: number; y: number };
  textPositionWidth: { x: number; y: number };
  textPositionHeight: { x: number; y: number };
  length: number;
  isDrawing: boolean;
  shapes: any[],
  currentShape: any,
  currentPage: number;
  totalPages: number;
  zoom: number;
  pdfDoc:any;
  viewportOffset: any,
  measuredLengthline:any;
  textPosition:any;
  measuredWidth:any;
  measuredHeight:any;
  rectangleTextPosition:any;
  selectedShape:any;
  isDragging: boolean;
  dragStart: any;
  deleteButtonPosition:any ;
  cachedPage:any;
  pdfImage:any;
  pdfImageSize:any;
  isError:boolean;
  errorMsg:string;
  status:string;
  loader:boolean
}

interface SS {
  id: any;
}

export default class ScalingController extends BlockComponent<Props, S, SS> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  canvasContainerRef: React.RefObject<HTMLDivElement>;
  renderTask: any;
  uploadFileID:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      token: "",
      uploadedFile: "",
      uploadFileUrl: "",
      fileName: "",
      numPages: 0,
      pageNumber: 1,
      scale: 1,
      zoomLevel: 100,
      tool: 'arrow',
      lines: [],
      rectangles: [],
      dragging: false,
      startPoint: null,
      pixels: 0,
      realWorldDistance: 0,
      lineWidth: 4,
      lineColor: "#34D399",
      rectangleBorderColor: "#34D399",
      rectangleBackgroundColor: "#34D3991F",
      isMounted:false,
      isDrawing: false,
      currentPage: 1,
      totalPages: 0,
      zoom: 1,
      pdfDoc:'',
      measuredLengthline:'',
      textPosition:'',
      viewportOffset: { x: 0, y: 0 },
      measuredWidth:'',
      unit: 'Meters(m)',
      endpoint1: { x: 0, y: 0 },
      endpoint2: { x: 0, y: 0 },
      textPositionWidth: { x: 0, y: 0 },
      textPositionHeight: { x: 0, y: 0 },
      length: 100,
      shapes: [],
      currentShape: null,
      measuredHeight:'',
      rectangleTextPosition:{},
      selectedShape:'',
      isDragging: false,
      dragStart: { x: 0, y: 0 },
      deleteButtonPosition:'',
      cachedPage:'',
      pdfImage:'',
      pdfImageSize:'',
      isError:false,
      errorMsg:'',
      status:'',
      loader:false
    };

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.canvasRef = React.createRef();
    this.canvasContainerRef = React.createRef();
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.uploadFileID) {
        if(responseJson.data){
          this.setState({uploadFileUrl:responseJson.data.attributes.drawing_pdf})
          await this.loadPDF()
        }
        else if(responseJson.error || responseJson.errors){
          this.setState({loader:false,isError:true,errorMsg:'Something went wrong',status:'failed'})
        }
      }
    
    }
    
  }
  async componentDidMount() {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.setState({loader:true})
    this.getUploadeFile()
    this.setState({ isMounted: true });
    window.addEventListener('keydown', this.handleKeyDown);
    const canvas = this.canvasRef.current;
    
    if (canvas) {
      canvas.addEventListener("mousemove", this.handleMouseMove);
      canvas.addEventListener("mousedown", this.handleMouseDown);
      canvas.addEventListener("mouseup", this.handleMouseUp);
      canvas.addEventListener("click", this.handleCanvasClick);
    }
  }
  
  async componentWillUnmount(): Promise<void> {
    if (this.state.uploadedFile) {
      URL.revokeObjectURL(this.state.uploadedFile);
    }
    this.setState({ isMounted: false });
    window.removeEventListener('keydown', this.handleKeyDown);
    const canvas = this.canvasRef.current;
    if (canvas) {
      canvas.removeEventListener("mousemove", this.handleMouseMove);
      canvas.removeEventListener("mousedown", this.handleMouseDown);
      canvas.removeEventListener("mouseup", this.handleMouseUp);
      canvas.removeEventListener("click", this.handleCanvasClick);
    }
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      this.handleNextPage();
    } else if (event.key === 'ArrowLeft') {
      this.handlePreviousPage();
    }
  };
  async getFileFromStorage() {
    try {
      const fileObjString = await getStorageData("fileObj");
      if (!fileObjString) return;

      const fileObj = JSON.parse(fileObjString);
      this.setState(
        {
          uploadFileUrl: fileObj.fileUrl,
          fileName: fileObj.fileMetaData.name,
        }        
      );
    } catch (error) {
      console.error("Error retrieving or parsing data from localStorage:", error);
    }
  }
  getUploadeFile=async ()=>{
    const token = await getStorageData("token")
    const fileId = await getStorageData("fileID")
    const header = {
      'Content-Type':configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadFileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_drawing/drawings/${fileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeleteShape = () => {
    const { shapes, selectedShape } = this.state;
    if (selectedShape) {
        const updatedShapes = shapes.filter(shape => shape !== selectedShape);
        this.setState({ shapes: updatedShapes, selectedShape: null, deleteButtonPosition: null , endpoint1:{x:0,y:0},endpoint2:{x:0,y:0}, measuredLengthline:null, measuredHeight:null,measuredWidth:null},
          ()=>this.redrawCanvas());
    }
};


  convertBase64ToBinary(dataURL: string): Uint8Array {
    try {
      if (!dataURL || !dataURL.includes(",")) {
        console.warn("Invalid Base64 data URL format");
        return new Uint8Array();
      }
  
      const base64String = dataURL.split(",")[1] || "";
      const binaryString = Buffer.from(base64String, "base64").toString("binary");
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
  
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      return bytes;
    } catch (error) {
      console.error("Error converting Base64 to binary:", error);
      return new Uint8Array();
    }
  }
  

  loadPDF = async () => {
    const url = this.state.uploadFileUrl;
    try {
      const pdf = await pdfjsLib.getDocument(url).promise;
      this.setState({ pdfDoc: pdf })
      this.setState({ totalPages: pdf.numPages }, () => {
        this.renderPage(this.state.pageNumber);
        this.setState({loader:false})
      });
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  };


renderingInProgress = false;
handleZoomIn = () => {
  this.setState(
    (prevState) => ({ zoom: prevState.zoom + 0.1 }),
    () => this.renderPage(this.state.pageNumber)
  );
};

handleZoomOut = () => {
  this.setState(
    (prevState) => ({ zoom: prevState.zoom - 0.1 }),
    () => this.renderPage(this.state.pageNumber)
  );
};
handleNextPage = () => {
  if (this.state.pageNumber < this.state.totalPages) {
    this.setState({ pageNumber: this.state.pageNumber + 1 });
    this.renderPage(this.state.pageNumber);
  }
};

handlePreviousPage = () => {
  if (this.state.pageNumber > 1) {
    this.setState({ pageNumber: this.state.pageNumber - 1 })
    this.renderPage(this.state.pageNumber);
  }
};
renderPage = (pageNumber: any) => {
  if (this.renderTask) {
    this.renderTask.cancel();
  }
  if (this.renderingInProgress) {
    console.warn("Rendering already in progress.");
    return;
  }

  this.renderingInProgress = true;
  
  this.state.pdfDoc.getPage(pageNumber).then((page: any) => {
    const viewport = page.getViewport({ scale: 1 });
    const scale = this.state.zoom; 

    const canvas = this.canvasRef.current
    if(canvas) {
    const context = canvas.getContext("2d");

    if (context) {

    canvas.width = viewport.width * scale;
    canvas.height = viewport.height * scale;

    const renderTask = page.render({
      canvasContext: context,
      viewport: page.getViewport({ scale }),
    });

    renderTask.promise
      .then(() => {
        
        const image = new Image();
        image.src = canvas.toDataURL("image/png");
        image.onload = () => {
          this.setState(
            {
              pdfImage: image, 
              pdfImageSize: { width: canvas.width, height: canvas.height }, 
              currentPage: pageNumber,
            },
            () => {
              this.redrawCanvas(); 
            }
          );
        };
        this.renderingInProgress = false;
      })
      .catch((error: any) => {
        console.error("Rendering error:", error);
        this.renderingInProgress = false;
      });
    }
  }
  });
};




handleCanvasMouseDown = (event: MouseEvent) => {
  const { tool } = this.state;
  if (tool === 'hand') {
    this.setState({
      isDragging: true,
      dragStart: { x: event.clientX, y: event.clientY },
    });
  }
};


handleCanvasMouseMove = (event: MouseEvent) => {
  const { tool, isDragging, dragStart, viewportOffset } = this.state;

  if (tool === 'hand' && isDragging) {
    const dx = event.clientX - dragStart.x;
    const dy = event.clientY - dragStart.y;

    this.setState(
      {
        dragStart: { x: event.clientX, y: event.clientY },
        viewportOffset: {
          x: viewportOffset.x + dx,
          y: viewportOffset.y + dy,
        },
      },
      () => this.redrawCanvas() 
    );
  }
};


handleMouseDown = (e: any) => {
  const validUnits = ["m", "cm", "mm", "km", "meters(m)", "centimeters(cm)", "kilometers(km)",'meter(m)', 
    "m", "cm", "mm", "km",
    "Meters(m)", "Centimeters(cm)", "Millimeters(mm)", "Kilometers(km)",
    "in", "ft", "yd", "mi",
    "Inches(in)", "Feet(ft)", "Yards(yd)", "Miles(mi)",
    'centimeter(cm)','kilometer(km)','kilometers','kilometer','centimeters','centimeter','meters','meter'];
  if(this.state.tool=='line' || this.state.tool=='rectangle'){

  
  if (this.state.pixels <= 0 || this.state.realWorldDistance <= 0) {
    this.setState(
      { isError: true, errorMsg: 'Invalid scale measurement', status: "failed" },
      () => {
        setTimeout(() => {this.setState({ isError: false });}, 3000);
      }
    );
    return;
  }

  if (!validUnits.includes(this.state.unit.trim().toLowerCase())) {
    this.setState(
      { isError: true, errorMsg: "Invalid unit measurement", status: "failed" },
      () => {
        setTimeout(() => { this.setState({ isError: false });}, 3000);
      }
    );
    return;
  }
}

  
  

  const canvas = this.canvasRef.current;
  if (canvas) {

  const rect = canvas.getBoundingClientRect();
  const x = (e.clientX - rect.left) / this.state.scale;
  const y = (e.clientY - rect.top) / this.state.scale;

  this.setState({
      isDrawing: true,
      startPoint: { x, y },
      currentShape: null, 
  });

  this.handleCanvasMouseDown(e);
}
};

handleMouseMove = (e: any) => {
  const canvas = this.canvasRef.current;
  if (canvas) {

  const context = canvas.getContext("2d");
  if (context) {

  const rect = canvas.getBoundingClientRect();
  const x = (e.clientX - rect.left) / this.state.scale;
  const y = (e.clientY - rect.top) / this.state.scale;

  const { isDrawing, startPoint, tool } = this.state;
  this.redrawCanvas(); 
  if (!isDrawing) return;

  if (tool === 'line' && startPoint) {
      this.setState({
          currentShape: {
              type: 'line',
              startX: startPoint.x,
              startY: startPoint.y,
              endX: x,
              endY: y,
          }
      });

      // Draw the preview line
      context.beginPath();
      context.moveTo(startPoint.x, startPoint.y);
      context.lineTo(x, y);
      context.strokeStyle = this.state.lineColor;
      context.lineWidth = this.state.lineWidth;
      context.stroke();
  } else if (tool === 'rectangle' && startPoint) {
      const width = x - startPoint.x;
      const height = y - startPoint.y;

      this.setState({
          currentShape: {
              type: 'rectangle',
              startX: startPoint.x,
              startY: startPoint.y,
              width: width,
              height: height,
          }
      });

      context.strokeStyle = this.state.rectangleBorderColor;
      context.fillStyle = '#34D3991F';
      context.lineWidth = this.state.lineWidth;
      context.strokeRect(startPoint.x, startPoint.y, width, height);
  }

  this.handleCanvasMouseMove(e);
}
}
};


handleMouseUp = () => {
  const { currentShape, scale, shapes, pixels, realWorldDistance, startPoint, unit } = this.state;
  this.setState({ isDragging: false, isDrawing: false });

  if (pixels <= 0 || realWorldDistance <= 0 || !currentShape || !startPoint) {
    return;
  }

  let updatedShape = { ...currentShape };
  if (!currentShape || !startPoint) return;

  const minDragDistance = 5;
  if (
    currentShape.type === "line" &&
    Math.abs(currentShape.startX - currentShape.endX) < minDragDistance &&
    Math.abs(currentShape.startY - currentShape.endY) < minDragDistance
  ) {
    return;
  }

  if (
    currentShape.type === "rectangle" &&
    (Math.abs(currentShape.width) < minDragDistance || Math.abs(currentShape.height) < minDragDistance)
  ) {
    return;
  }

  const calculateRealDistance = (x1: number, y1: number, x2: number, y2: number) => {
    const pixelDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    let realDistance = (pixelDistance * realWorldDistance) / pixels;
  
    const selectedUnit = unit.toLowerCase().trim();
  
    switch (selectedUnit) {
      case "mm":
      case "millimeter":
      case "millimeters":
      case "millimeters(mm)":
        realDistance *= 1000; 
        break;
  
      case "cm":
      case "centimeter":
      case "centimeters":
      case "centimeters(cm)":
        realDistance *= 100; 
        break;
  
      case "km":
      case "kilometer":
      case "kilometers":
      case "kilometers(km)":
        realDistance /= 1000; 
        break;
  
      case "in":
      case "inch":
      case "inches":
      case "inches(in)":
        realDistance *= 39.3701;
        break;
  
      case "ft":
      case "foot":
      case "feet":
      case "feet(ft)":
        realDistance *= 3.28084;
        break;
  
      case "yd":
      case "yard":
      case "yards":
      case "yards(yd)":
        realDistance *= 1.09361; 
        break;
  
      case "mi":
      case "mile":
      case "miles":
      case "miles(mi)":
        realDistance /= 1609.34;
        break;
  
      case "m":
      case "meters":
      case "meters(m)":
      default:
        break;
    }
  
    return `${realDistance.toFixed(2)} ${this.getShortUnit(unit)}`
  };
  
  

  if (currentShape.type === "line") {
    const { startX, startY, endX, endY } = currentShape;
    updatedShape.length = calculateRealDistance(startX, startY, endX, endY);
    updatedShape.textPosition = { x: (startX + endX) / 2, y: (startY + endY) / 2 + 20 };
  } else if (currentShape.type === "rectangle") {
    const { startX, startY, width, height } = currentShape;
    const endX = startX + width;
    const endY = startY + height;

    updatedShape.measuredWidth = calculateRealDistance(startX, startY, endX, startY);
    updatedShape.measuredHeight = calculateRealDistance(startX, startY, startX, endY);
    updatedShape.textPositionWidth = { x: startX + width / 2, y: startY + height + 20 };
    updatedShape.textPositionHeight = { x: startX + width + 45, y: startY + height / 2 };
  }

  this.setState(
    {
      shapes: [...shapes, updatedShape],
      currentShape: null,
    },
    () => {
      this.redrawCanvas();
    }
  );
};




redrawCanvas = () => {
  const canvas = this.canvasRef.current;
  if (canvas) {

  const context = canvas.getContext("2d");
  if (context) {

  context.clearRect(0, 0, canvas.width, canvas.height);

  const { pdfImage, pdfImageSize, viewportOffset } = this.state;

  if (pdfImage && pdfImageSize) {
    canvas.width = pdfImageSize.width; 
    canvas.height = pdfImageSize.height;
    
    context.setTransform(1, 0, 0, 1, viewportOffset.x, viewportOffset.y);
    context.drawImage(pdfImage, 0, 0, pdfImageSize.width, pdfImageSize.height);
    context.setTransform(1, 0, 0, 1, 0, 0); 
  }

  this.renderShapes(context);
}
  }
};




 getShortUnit = (unit: string) => {
  const unitMap: { [key: string]: string } = {
    "mm": "mm", "millimeter": "mm", "millimeters": "mm", "millimeters(mm)": "mm",
    "cm": "cm", "centimeter": "cm", "centimeters": "cm", "centimeters(cm)": "cm",
    "m": "m", "meter": "m", "meters": "m", "meters(m)": "m",
    "km": "km", "kilometer": "km", "kilometers": "km", "kilometers(km)": "km",
    "in": "in", "inch": "in", "inches": "in", "inches(in)": "in",
    "ft": "ft", "foot": "ft", "feet": "ft", "feet(ft)": "ft",
    "yd": "yd", "yard": "yd", "yards": "yd", "yards(yd)": "yd",
    "mi": "mi", "mile": "mi", "miles": "mi", "miles(mi)": "mi"
  };

  return unitMap[unit.toLowerCase().trim()] || "m";
};

renderShapes = (context: any) => {
  const { shapes, lineColor, pixels, realWorldDistance, lineWidth } = this.state;
  if (pixels <= 0 || realWorldDistance <= 0) {
    return;
  }

  shapes.forEach((shape) => {
    if (shape.type === "line") {
      context.beginPath();
      context.moveTo(shape.startX, shape.startY);
      context.lineTo(shape.endX, shape.endY);
      context.strokeStyle = lineColor;
      context.lineWidth = lineWidth;
      context.stroke();

      this.drawEndpoint(context, shape.startX, shape.startY);
      this.drawEndpoint(context, shape.endX, shape.endY);

      if (shape.length) {
        const text = `${shape.length}`;
        this.drawLabel(context, text, shape.textPosition.x, shape.textPosition.y);
      }
    } else if (shape.type === "rectangle") {
      context.globalAlpha = 0.2;
      context.fillStyle = "#34D399";
      context.fillRect(shape.startX, shape.startY, shape.width, shape.height);
      context.globalAlpha = 1.0;

      context.strokeStyle = "#34D399";
      context.lineWidth = lineWidth;
      context.strokeRect(shape.startX, shape.startY, shape.width, shape.height);

      this.drawEndpoint(context, shape.startX, shape.startY);
      this.drawEndpoint(context, shape.startX + shape.width, shape.startY + shape.height);

      if (shape.measuredWidth) {
        this.drawLabel(context, `${shape.measuredWidth}`, shape.textPositionWidth.x, shape.textPositionWidth.y);
      }
      if (shape.measuredHeight) {
        this.drawLabel(context, `${shape.measuredHeight}`, shape.textPositionHeight.x, shape.textPositionHeight.y);
      }
    }
  });
};


handleCanvasClick = (e: any) => {
  const canvas = this.canvasRef.current;
  if (canvas) {

  const rect = canvas.getBoundingClientRect();
  const x = (e.clientX - rect.left) / this.state.scale;
  const y = (e.clientY - rect.top) / this.state.scale;

  const { shapes } = this.state;
  let selectedShape = null;
  for (let shape of shapes) {
    const isPointOnLine = this.isPointOnLine(x, y, shape)
    const isPointInsideRect = this.isPointInsideRect(x, y, shape)
    if (shape.type === "line" && isPointOnLine) {
      selectedShape = shape;
      this.setState({ 
        measuredLengthline: shape.length, 
        measuredHeight: null, 
        measuredWidth: null,
        endpoint1: { x: shape.startX, y: shape.startY },
        endpoint2: { x: shape.endX, y: shape.endY },
        deleteButtonPosition: { x: shape.endX+5, y: shape.endY+5 },
      });
      break;
    } else if (shape.type === "rectangle" &&isPointInsideRect) {
      selectedShape = shape;
      const bottomRightX = shape.startX + shape.width;
      const bottomRightY = shape.startY + shape.height;
      this.setState({ 
        measuredHeight: shape.measuredHeight,
        measuredWidth: shape.measuredWidth,
        measuredLengthline: null, 
        endpoint1: { x: shape.startX, y: shape.startY },
        endpoint2: { x: shape.startX + shape.width, y: shape.startY + shape.height },
        deleteButtonPosition: { x: bottomRightX , y: bottomRightY },
      });
      break;
    }
  }

  if (selectedShape) {
    this.setState({ selectedShape });
  }
}
};

drawEndpoint = (context: any, x: number, y: number) => {
  context.beginPath();
  context.arc(x, y, 5, 0, 2 * Math.PI);
  context.fillStyle = "#34D399"; 
  context.fill();
};

drawLabel = (context: any, text: string, x: number, y: number) => {
  context.font = "bold 14px Poppins";
  context.textAlign = "center";
  context.textBaseline = "middle";

  const textWidth = context.measureText(text).width + 16;
  const textHeight = 24;

  context.fillStyle = "#34D399";
  context.beginPath();
  context.roundRect(x - textWidth / 2, y - textHeight / 2, textWidth, textHeight, 40);
  context.fill();

  context.fillStyle = "white";
  context.fillText(text, x, y);
};

  componentDidUpdate(prevProps: Props, prevState: S) {
    const canvas = this.canvasRef.current;
    const context = canvas?.getContext("2d");
  
    if (
      prevState.pageNumber === this.state.pageNumber &&
      prevState.scale === this.state.scale &&
      prevState.lines === this.state.lines &&
      prevState.rectangles === this.state.rectangles
    ) {
      return;
    }
  
    if (canvas && context) {
      console.warn("Updating Canvas...");
      context.clearRect(0, 0, canvas.width, canvas.height);
  
      this.renderPageContent();
  
      this.renderShapes(context);
    }
  }
  
  renderPageContent() {
    const { uploadFileUrl } = this.state;
    if (uploadFileUrl) {
      this.loadPDF();
    }
  }
    handelClose = ()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DrawingDashboard"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    
  }
     selectTool = (tool: 'line' | 'rectangle' | 'arrow' | 'hand') => {
      if (tool === 'hand') {
        this.setState({ dragging: false, dragStart: { x: 0, y: 0 } }); 
      }
    this.setState({ tool });
  };
    handleGoBack=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DrawingDashboard"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleInputChangePixels = (value: any) => {
    if (value === '' || /^[+]?\d+$/.test(value)) {
      this.setState({ pixels: value });
    }
  };
  
  
  handleInputChangeDis = (value: any) => {
    if (value === '' || /^[+]?\d*\.?\d{0,2}$/.test(value)) {
      this.setState({ realWorldDistance: value });
    }
  };
  handleInputChangeUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
      this.setState({ unit: value });
  };
  
  
 
  calculateArea = () => {
    const { measuredWidth, measuredHeight } = this.state;
  
    if (!measuredWidth || !measuredHeight) {
      return "Invalid measurement";
    }
  
    const widthMatch = measuredWidth.match(/^([\d.]+)\s*([a-zA-Z]+)$/);
    const heightMatch = measuredHeight.match(/^([\d.]+)\s*([a-zA-Z]+)$/);

    const width = parseFloat(widthMatch[1]);  
    const height = parseFloat(heightMatch[1]); 
    const unit = widthMatch[2]; 
  
    return `${(width * height).toFixed(2)} sq. ${unit}`;
  };
  
  
  
  
isPointOnLine = (px: number, py: number, shape: any) => {
  const { startX, startY, endX, endY } = shape;
  
  const distanceToStart = Math.sqrt((px - startX) ** 2 + (py - startY) ** 2);
  const distanceToEnd = Math.sqrt((px - endX) ** 2 + (py - endY) ** 2);
  const lineLength = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
  
  return Math.abs(distanceToStart + distanceToEnd - lineLength) < 5; 
};

isPointInsideRect = (px: number, py: number, shape: any) => {
  const { startX, startY, width, height } = shape;
  return px >= startX && px <= startX + width && py >= startY && py <= startY + height;
};
  
  
}
// Customizable Area End