Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.projectDetialsGetAPiEndPoint = "bx_block_profile/projects/project_dashboard";
exports.checkTokenAPiEndPoint = "bx_block_login/logins/check_token";
exports.getPieChartResponse ="bx_block_profile/project_rooms/project_rooms_cost_list";
exports.subTaskAPIEndPoint = "bx_block_profile/project_rooms";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.roomCostBreakdown = "Room Cost Breakdown ";
exports.totalInvoices= "Total Invoices:";
exports.invoiceTitle = 'Invoice Summary';
exports.currency = '£';
exports.totalCost= "Total Cost:";
exports.viewMoreBtn = 'View more';
exports.tasklistSummary = 'Tasklist Summary';
exports.tasksText = 'Tasks';
exports.projectDuration = 'Project Duration';
exports.clientDetails = 'Client Details';
exports.address = 'Address';
exports.created = 'Start Date:';
exports.dueDate = 'Due Date:';
exports.expected = 'Expected:';
exports.state = '--';
exports.addressText = '--';
exports.name = '--';
exports.gmail = '--';
exports.phNum = '--';
exports.doubleDash = '--';
exports.onGoing = 'ONGOING';
exports.paused = 'PAUSED';
exports.completed = 'COMPLETED';
exports.price = '9,675.67';
exports.textHeader="Detailed Cost Breakdown";
exports.data = [
  { name: "Room Name", value: 400 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 200 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
];
exports.COLORS = ["#60A5FA", "#F87171", "#FCD34D", "#FF8042", '#F472B6', '#ABCDD5', '#818CF8', '#34D399'];
exports.invoiceData = [
  { name: "Paid:", value: 400 },
  { name: "Unpaid:", value: 300 },
  { name: "Upcoming:", value: 300 },
  { name: "OverDue:", value: 100 },
];
exports.invoiceColor = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
exports.BARCOLORS = ["#3B82F6", "#F59E0B", "#10B981", "#EF4444", '#94A3B8'];
exports.barData = [
  {
    name: "To Do",
    uv: 40,
    amt: 2400,
  },
  {
    name: "Progress",
    uv: 74,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Completed",
    uv: 20,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Blocked",
    uv: 27,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Archived",
    uv: 27,
    pv: 3908,
    amt: 2000,
  },
];

exports.TaskListDummyData = {
  "data": [
      {
          "id": "1",
          "type": "project_rooms_task_lists",
          "attributes": {
              "id": 1,
              "project_id": 1,
              "room_id": 1,
              "project_room_id": 1,
              "task_list_name": "tasklist1",
              "task_list_cost": 5.0,
              "project_tasks": [
                  {
                      "id": "1",
                      "type": "project_rooms_task_list_tasks",
                      "attributes": {
                          "id": 1,
                          "project_id": 1,
                          "status": "to_do",
                          "project_rooms_task_list_id": 1,
                          "count": 1,
                          "task_list_name": "task4002",
                          "specifications": [
                              {
                                  "id": 1,
                                  "specification_name": "spec4002",
                                  "area": "7",
                                  "quantity": "1",
                                  "measurement_unit": "unit",
                                  "labour_cost": "2.0",
                                  "material_cost": "3.0",
                                  "unit_total": "5.0",
                                  "total_cost": 5.0,
                                  "task_id": 2,
                                  "specification_id": 2
                              }
                          ]
                      }
                  }
              ]
          }
      }
  ]
}



// Customizable Area End