import React,{Suspense} from "react";

import {
  // Customizable Area Start
  Box,
  BoxProps,
  Button,
  Grid,
  InputBase,
  Paper,
  Typography,
  styled,
  withStyles,
  Dialog,
  DialogActions
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Dropzone from "react-dropzone";
import { scalingIcon, pdfIcon, pdfFileIcon, userIcon, cancelIcon, pdfFileWarningIcon, warningIcon, reload } from "./assets";
import Loader from "../../../components/src/Loader.web";
interface CustomButtonBoxProps extends BoxProps {
  toggle: string;
  type: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DrawingScalingDashboardController, {
  Props
} from "./DrawingScalingDashboardController"

;
import { Clear, Search, } from "@material-ui/icons";

import MyProfileHeader from "../../../components/src/MyProfileHeader";

export default class DrawingScalingDashboard extends DrawingScalingDashboardController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  UploadButton = styled("button")(({ isEnabel }:any) => ({
    borderRadius: "8px",
    border: "1px solid #C5E0E7",
    fontWeight: 600,
    fontSize: "16px",
    height: "56px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "10px 16px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth: 0,
    cursor: "pointer",
    backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
    color: isEnabel ? "#FFFFFF" : "#64748B",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
    },
  }));
  InfoCard = ({ id, title, fileCount, name }: any) => {
      
    return (
        <Grid item xs={12} sm={6} lg={3} xl={3} style={{ padding: '10px' }}>
            <Box style={{  backgroundColor:'#3B82F6',borderTop:'8px solid #3B82F6',borderRadius: '12px 12px 11px 12px'}}>
            <Paper className="third_grid_box" style={{ borderRadius: '11px', marginTop:'-3' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Typography className="thirdGridHeadingTypo">{title}</Typography>
                    </Box>
                   
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Box>
                        <Box className="thirdGridDataTypoBox">
                          
                           <img style={{marginBottom:'6px'}} src={pdfIcon}/>&nbsp;
                         
                       
                            <Typography className="thirdGridNameTypo">Files:</Typography>&nbsp;
                          
                          <Box>  
                            <Typography className="thirdGridNameTypo">{fileCount}</Typography>
                            </Box>
                        </Box>
                        <Box className="thirdGridDataTypoBox">
                          <Box>
                            <img style={{marginTop:'0px'}} src={userIcon}/>&nbsp;
                          </Box>
                          <Box>
                            <Typography className="thirdGridNameTypo">{name}</Typography>
                            </Box>
                        </Box>
                    </Box>
                  
                </Box>
            </Paper>
            </Box>
        </Grid>
    );
};
getFileErrorMessage = (isFileTooLarge: boolean, errorMessage: string | null, fileSize: string) => {
  if (isFileTooLarge) {
    return "File size must not exceed 10 MB.";
  }

  if (errorMessage) {
    return "File loading error";
  }

  return fileSize;
}

onDrop = (acceptedFiles: File[]) => {
  console.log('@@!!!',acceptedFiles[0]);
  const file = acceptedFiles[0];
  
  if (file) {
    const fileSizeInMB = file.size / (1024 * 1024);

    const transformedFile = {
      name: file.name,
      size: `${fileSizeInMB.toFixed(2)} MB`,
    };
    if (file.type !== "application/pdf") {
      this.setState({
        errorMessage: "Only PDF files are allowed.",
        fileMetadata: transformedFile,
        file: file,
      });
      return;
    }
   
    if (fileSizeInMB > 10) {
      this.setState({
        errorMessage: null,
        fileMetadata: transformedFile,
        file: file,
        fileSizeError:true
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
        this.setState({
        fileUrl : reader.result as string,
      fileMetadata: transformedFile,
      file: file,
      errorMessage: null,
      fileSizeError:false
      });
      
    };
    reader.readAsDataURL(file); 
    
  }
};
renderFileBlock = (fileMetaData: { name: string; size: string } | null, index: number) => {
  if (!fileMetaData) {
    return null; 
  }

  const fileSizeInMB = parseFloat(fileMetaData.size);

  const isFileTooLarge = fileSizeInMB >10;
  const fileErrorMessage = this.getFileErrorMessage(isFileTooLarge, this.state.errorMessage, fileMetaData.size);
  
  return (
    <>
      {this.state.errorMessage && (
        <Box style={{display:'flex',alignItems:'flex-start',gap:'8px',marginTop:'6px'}}>
        <img src={warningIcon} alt=""/>
        <Typography
          style={{
            color:'#DC2626',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
          }}
        >
          {this.state.errorMessage}
        </Typography>
        </Box>
      )}
      
      <Typography className="attachedFileTypo">Attached Files:</Typography>
      
      {this.state.file && (
        <Paper
          key={index}
          style={{
            boxShadow: 'rgba(0.1, 0, 0, 0.1) 0px 4px 5px 5px',
            borderRadius: '8px',
            padding: '6px 8px',
            marginTop: '8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: isFileTooLarge || this.state.errorMessage ? '2px solid #DC2626' : '2px solid #34D399',
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
            <Box>
              <img src={isFileTooLarge || this.state.errorMessage ? pdfFileWarningIcon : pdfFileIcon} alt="pdfFileIcon" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography className="fileNameTypo">{fileMetaData.name}</Typography>
              <Typography
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                  color: isFileTooLarge || this.state.errorMessage ? '#DC2626' : '#1E293B',
                }}
              >
                {fileErrorMessage}
              </Typography>
            </Box>
          </Box>
          <Box style={{display:'flex',gap:8}}>
          { isFileTooLarge && <>
          <Box onClick={this.handleRemoveUploadedFile}>
            <img src={reload} style={{marginTop:'5px',height:18,width:18,cursor:'pointer'}}/>
          </Box>
          </>}
          { this.state.errorMessage=='Only PDF files are allowed.' && <>
          <Box onClick={this.handleRemoveUploadedFile}>
            <img src={reload} style={{marginTop:'5px',height:18,width:18,cursor:'pointer'}}/>
          </Box>
          </>}
          <Box onClick={this.handleRemoveUploadedFile}>
            <img src={cancelIcon} alt="cancelIcon" />
          </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
          
                        <MyProfileHeader navigation={this.props.navigation}  tabName={'Scaling&Drawings'}/>
                     
                             <CustomUploadDialog  PaperProps={{
                      style: {
                        width: '584px',
                        borderRadius: "16px",
                        padding: "24px 24px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} open={this.state.openUploadDialog} onClose={this.handleCloseUploadDialog}>
                      <Loader loading={this.state.loader}/>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "40px",
            }}
          >
            <Typography className="dialogTitleTypo">Upload File</Typography>
            <Typography
              data-test-id="handleCloseUploadDialog"
              onClick={this.handleCloseUploadDialog}
              style={{
                color: "black",
                cursor: "pointer",
                width: "20px",
              }}
            >
              <Clear />
            </Typography>
          </Box>
          <Box>
      {(this.state.errorMessage || this.state.fileSizeError ||  !this.state.file) &&  <Dropzone
          onDrop={this.onDrop}
          multiple={false}
        >
          {({ getRootProps, getInputProps }:any) => (
            <Box
              {...getRootProps()}
              style={{
                border: "2px dashed #CBD5E1",
                borderRadius: "8px",
                padding: "40px",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: "#F8FAFC",
              }}
            >
              <input {...getInputProps()} />
              <Typography
                style={{
                  color:'#292524',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px',
                  textAlign: 'center',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}
              >
               Drag & Drop Single File Here
              </Typography>
              <Typography
                style={{
                  marginTop:'4px',
                  color:'#475569',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  textAlign: 'center',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}
              >
                Acceptable format: PDF 
              </Typography>
              <Typography
                style={{
                  color:'#475569',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  textAlign: 'center',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}
              >
                (Max size: 10MB)
              </Typography>
            </Box>
          )}
        </Dropzone>}

        
         {this.state.file && this.renderFileBlock(this.state.fileMetadata, 0)} 
       
        
      </Box>
         
          <DialogActions
            style={{
              marginTop: "38px",
              gap: "16px",
              padding: 0,
            }}
          >
            <CancelButton data-test-id="handleCloseUploadDialog" onClick={this.handleCloseUploadDialog}>Cancel</CancelButton>
            <this.UploadButton disabled={this.state.errorMessage || this.state.fileSizeError || !this.state.file} isEnabel={this.state.file && !this.state.errorMessage && !this.state.fileSizeError} onClick={this.handleUploadFile}>
            {this.state.file && !this.state.errorMessage && !this.state.fileSizeError ? 'Upload' : 'Upload'}
              </this.UploadButton>
          </DialogActions>
        </Box>
      </CustomUploadDialog>
                        <Box data-test-id="handleScroll" sx={{ padding: '30px 40px', bgcolor: '#F0F5F7',height:'100%' }} onScroll={this.handleScroll} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                            <Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',bgcolor:'#FFFFFF',height:'60px', padding:'8px 16px 8px 16px', borderRadius:'8px' }}>

                                <Box className="mainBoxFirst">
                              <Box className="OptionBox">
                                <Box className="singleOptionBox">
                                  <CustmoButtonBox
                                  type="Draft"
                                  toggle={this.state.toggle}
                                  data-test-id="handleToggleDraft"
                                  onClick={() => this.handleToggle("Draft")}
                                  >
                                   Draft
                                  </CustmoButtonBox>
                                  <CustmoButtonBox
                                  type="Ongoing"
                                  toggle={this.state.toggle}
                                  data-test-id="handleToggleOngoing"
                                  onClick={() => this.handleToggle("Ongoing")}
                                  >
                                    Ongoing
                                  </CustmoButtonBox>
                                  <CustmoButtonBox
                                  type="Proposed"
                                  toggle={this.state.toggle}
                                  data-test-id="handleToggleProposed"
                                  onClick={() => this.handleToggle("Proposed")}
                                  >
                                    Proposed
                                  </CustmoButtonBox>
                                  <CustmoButtonBox
                                  type="Paused"
                                  toggle={this.state.toggle}
                                  data-test-id="handleTogglePaused"
                                  onClick={() => this.handleToggle("Paused")}
                                  >
                                    Paused
                                  </CustmoButtonBox>

                                </Box> 
                            
                              </Box>
                            
                                </Box>

                           

                                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 16 }}>
                                        <Box>
                                           
                                        </Box>
                                        <Box>
                                        <ScalingButton data-test-id="handleButtonScaling" onClick={this.handleButtonScaling}>
                                          <img src={scalingIcon} alt="img"/>
                                          <Typography style={{
                                            color:'#325962',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            textUnderlinePosition: 'from-font',
                                            textDecorationSkipInk: 'none',
                                            textTransform:'none',
                                            
                                          }}>Scalings</Typography>
                                          
                                          
                                           </ScalingButton>
                                        </Box>
                                       
                                        <Box>
                                        <SearchContainer>
      <StyledSearchIcon />
      <StyledInputBase
        onChange={this.handleChangeSearch}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        value={this.state.searchQuery}
      />
    </SearchContainer>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                            
                            <Box className="main_box">
                                
                                <Box className="main_paper2">

                                    <Grid container spacing={3}>
                                     { this.state.DrawingDataList.data.length>0 ? 
                                     (
                                     this.state.DrawingDataList.data.slice(0, this.state.visibleCount).map((item: any,index) => (
                                      
                                    <Suspense fallback={<CircularProgress />} key={item.id}>
                                            <this.InfoCard
                                                id={item.id}
                                                key={index}
                                                title={item.attributes.project_name}
                                                fileCount={item?.attributes?.uploaded_files?.length || 0}
                                                name={`${item.attributes.addressable?.name || ''} ${item.attributes.addressable?.last_name || ''}` }
                                                
                                            />
                                        </Suspense>
                                        )))
                                      : <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                                        <Typography>{this.state.DrawingDataList.meta.message}</Typography>
                                        </Box>}
                                     

                                    </Grid>

                                </Box>
                                {this.state.visibleCount < this.state.DrawingDataList.data.length && !this.state.DrawingDataList.meta && (
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <CircularProgress />
                </Box>
              )}
                            </Box>
                            
                           
                        </Box>
                       
                    </MainWrapper>
        
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  height:'100%',
  "& .main_box": {
      marginTop: '24px'
  },
  "& .main_paper": {
      marginTop: '32px',
      marginBottom: '32px',
      borderRadius: '12px'
  },
  "& .main_paper1": {
      marginTop: '16px',
      marginBottom: '32px',
      borderRadius: '12px'
  },
  "& .main_paper2": {
      marginTop: '32px',
      marginBottom: '24px',
      borderRadius: '12px'
  },
  "& .first_grid_box": {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '0px 24px'
  },
  "& .second_grid_box": {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '24px 24px'
  },
  "& .third_grid_box": {
      padding: '16px 24px'

  },
  "& .thirdGridHeadingTypo": {
    display: '-webkit-box',
    overflow: 'hidden', 
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .thirdGridDataTypoBox": {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '4px',
      alignItems:'center'
  },
  "& .thirdGridDataTypoBox2": {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
  },
  "& .thirdGridDataTypo": {
      color: '#64748B',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none'
  },
  "& .thirdGridNameTypo": {
    color:'#64748B',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .mainBoxFirst":{
    display:'flex',
    justifyContent:'space-between'

  },
  "& .OptionBox": {
    display: "flex",
    justifyContent: "start",
  },
  "& .singleOptionBox": {
    display: "flex",
    backgroundColor: "#F0F5F7",
    borderRadius: "50px",
    padding: "4px",
    height:'36px',
    gap:'8px'
  },
  "& .search": {
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
}));
const styles = {
  root: {
    borderRadius: '50px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    padding: '6px 12px',
    cursor: 'pointer',
    fontSize: '14px',
    backgroundColor: (props: CustomButtonBoxProps) =>
      props.toggle === props.type ? '#E0EDF0' : '#F0F5F7',
    border: (props: CustomButtonBoxProps) =>
      props.toggle === props.type ? '1px solid #D3E4E9' : 'none',
    color: (props: CustomButtonBoxProps) =>
      props.toggle === props.type ? '#325962' : '#64748B',
    transition: 'all 0.3s ease',
  },
};

const CustmoButtonBox = withStyles(styles)((props: CustomButtonBoxProps) => {
  const { toggle, type, ...otherProps } = props;
  return <Box {...otherProps} />;
});

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border:'1px solid #CBD5E1',
  padding: theme.spacing(0.5, 1),
  maxWidth: '400px',
  boxShadow: `0px 2px 4px ${theme.palette.divider}`,
}));

const StyledSearchIcon = styled(Search)(({ theme }) => ({
  marginRight: 0,
  color: theme.palette.text.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    transition: theme.transitions.create(['width']),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      color:'#94A3B8',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      '&:focus': {
        width: '250px',
        color:'#334155',
      },
    },
  },
}));
const ScalingButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  height: "44px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth:0,
  '&:hover': {
    backgroundColor: '#E0EDF0', 
  },


});
const CustomUploadDialog = styled(Dialog)({
  "& .dialogTitleTypo":{
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .fileNameTypo":{
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .fileSizeTypo":{
    color:'#64748B',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .attachedFileTypo":{
    marginTop:'16px',
    color:'#334155',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  }


});
const CancelButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#325962",
  fontWeight: 600,
  fontSize: "16px",
  height: "56px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth:0,
  '&:hover': {
    backgroundColor: '#E0EDF0', 
  },
});
// Customizable Area End
