// Customizable Area Start
import React from "react";

import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import Select, { components } from "react-select";
import {
  image,
  checkedIcon,
  success,
  unCheckIcon,
  createIcon,
  accountDel,
  arrows,
} from "./assets";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";
import AddNewMemberController, { Props } from "./AddNewMemberController";
import { ExpandLess, ExpandMore,ControlPoint } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { capitalLetterMethod } from "../../../components/src/utils";

import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  ListItemText,
  CardMedia,
  InputAdornment,
  Checkbox,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  InputLabel,
  Select as MUISelect,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { ToastContainer } from "react-toastify";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomSelect from "./AddMembersModal.web";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #CBD5E1",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #CBD5E1",
        },
      },
    },
  },
});

const RenameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.5)",
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  fontSize: "16px",
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});

const FirstAndLastNameInputGroup = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  marginTop: "32px",
});

const InputGroup = styled("div")({
  marginBottom: "1rem",
  width: "100%",
});

const RoleDiv = styled("div")({
  backgroundColor: "#F8FAFC",
  borderRadius: "50px",
  width: "100%",
  display: "flex",
  padding: "4px",
  justifyContent: "space-between",
});

const inputStyles = {
  borderRadius: "8px",
};
const placeholderStyles = {
  fontFamily: "Poppins, sans-serif",
};

export default class AddNewMember extends AddNewMemberController {
  constructor(props: Props) {
    super(props);
  }

  renderInviteMember = () => {
    return (
      <Modal
        data-test-id="edit-invite-member-id"
        onClose={() => this.handleEditDetailInviteModal()}
        open={this.state.isEditDetailModalInviteOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.editModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Invite & Add Member (s)</Typography>
            <Close
              data-test-id="invite-close-member-id"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditDetailInviteModal()}
            />
          </Box>

          <Box>
            <FirstAndLastNameInputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    placeholder="Enter first name"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberFirstName}
                    name="memberFirstName"
                    data-test-id="inputchange-invite-id"
                    onChange={(e) => {
                      this.handleInputDataInviteChange(e);
                    }}
                    error={!!this.state.memberFirstNameInviteError}
                    InputProps={{
                      style: inputStyles,
                      inputProps: {
                        maxLength: 50,
                        style: placeholderStyles,
                      },
                    }}
                  />
                  {this.state.memberFirstNameInviteError && (
                    <Typography style={webStyle.errorText}>
                      {this.state.memberFirstNameInviteError}
                    </Typography>
                  )}
                </Box>
              </InputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    placeholder="Enter last name"
                    size="medium"
                    variant="outlined"
                    data-test-id="txtInputLastNameInvite"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberLastName}
                    error={!!this.state.memberLastNameInviteError}
                    name="memberLastName"
                    InputProps={{
                      style: inputStyles,
                      inputProps: {
                        maxLength: 50,
                        style: placeholderStyles,
                      },
                    }}
                    onChange={(e) => {
                      this.handleInputDataInviteChange(e);
                    }}
                  />
                  {this.state.memberLastNameInviteError && (
                    <Typography style={webStyle.errorText}>
                      {this.state.memberLastNameInviteError}
                    </Typography>
                  )}
                </Box>
              </InputGroup>
            </FirstAndLastNameInputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  placeholder="Enter email"
                  size="medium"
                  variant="outlined"
                  data-test-id="txtInputEmailInvite"
                  fullWidth
                  onChange={(e) => {
                    this.handleInputDataInviteChange(e);
                  }}
                  error={
                    !!this.state.emailFormatInviteError || !!this.state.memberEmailInviteError || !!this.state.inviteMemberError
                  }
                  InputLabelProps={
                    { shrink: true }
                  }
                  value={this.state.memberEmail}
                  name="memberEmail"
                  InputProps={{
                    style: inputStyles,
                    inputProps:
                     {
                      style: placeholderStyles,
                    },
                  }
                }/>

                {this.renderErrorMessage()
                } </Box>
            </InputGroup>
            <InputGroup>
              <Box>
                <Typography style={{  fontWeight: 600,  color: "#334155",  fontSize: "16px",
                  }}
                >
                  Job Title
                </Typography>
                <TextField
                  placeholder="Enter job title"
                  size="medium"
                  variant="outlined"
                  InputLabelProps={
                    { shrink: true }}
                  data-test-id="txtInputJobTitleInvite"
                  fullWidth
                  value={this.state.jobTitle}
                  name="jobTitle"
                  onChange={(e) => { this.handleInputDataInviteChange(e);
                  }
                }
                  error={!!this.state.jobTitleInviteError}
                  InputProps={{
                    style: inputStyles,
                    inputProps: { maxLength: 50,
                      style: placeholderStyles,
                    },
                  }}
                />
                {this.state.jobTitleInviteError && (
                  <Typography style={webStyle.errorText}>
                    {this.state.jobTitleInviteError}
                  </Typography>
                )}
              </Box>
            </InputGroup>
            <InputGroup>
              <Typography
                style={{
                  fontWeight: 600,
                  color: "#334155",
                  fontSize: "16px",
                }}
              >
                Role
              </Typography>
              <RoleDiv>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "owner",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="invite-owner-role"
                  onClick={() => {
                    this.handleRoleClick("owner");
                  }}
                >
                  Owner </Button>
                <Button
                  style={
                    this.ternarHandler(
                    this.state.role === "manager",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )
                }
                  data-test-id="invite-manager-role"
                  onClick={() => {
                    this.handleRoleClick("manager");}
                } >
                  Manager
                </Button>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "employee",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton)
                }
                  data-test-id="invite-employee-role"
                  onClick={() => {
                    this.handleRoleClick("employee");  }
                }
                >
                  Employee
                </Button>
              </RoleDiv>
            </InputGroup>
          </Box>

          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              data-test-id="isAnotherMember-invite-data-id"
              onClick={() => {
                this.state.isAddingAnotherMember
                  ? this.handleSkipMember()
                  : this.CancelInviteMember();
              }}
            >
              {this.state.isAddingAnotherMember ? "Skip" : "Cancel"}
            </RenameButton>

            <RenameButton onClick={this.handleSubmitInviteData} data-test-id="invite-submit-btn">
              Invite
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderInvitedMemberList = () => {
    const { member } = this.state;

    return (
      <Modal
        data-test-id="invite-closed-data-id"
        onClose={() => this.handleInviteMember()}
        open={this.state.isInvitedMember}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.editModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Invite & Add Member (s)</Typography>
            <Close
              data-test-id="invite-closing-data-id"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleInviteMember()}
            />
          </Box>

          <MemberCardContainer>
            <Box>
              <div
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  marginBottom: "1rem",
                }}
              >
                {member.length === 0 ? (
                  <Box
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      width: "100%",
                      color: "#475569",
                      textAlign: "center",
                    }}
                  >
                    No Members Present
                  </Box>
                ) : (
                  member.map((item) => {
                    return (
                      <MemberCard key={item?.id}>
                        <MemberCardLeftData>
                          <CardNameLabel>{item.user_name}</CardNameLabel>
                          <CardEmailLabel>{item?.email}</CardEmailLabel>
                          <CardEmailLabel>{item.job_title}</CardEmailLabel>
                        </MemberCardLeftData>
                        <MemberCardRightData>
                          <RightSideLabel>
                            {capitalLetterMethod(item.role_id)}
                          </RightSideLabel>
                          <RightSideRemoveLabel
                            data-test-id="remove_invited_member_data_id"
                            onClick={() =>
                              this.removeInvitedMemberApiCall(item.id)
                            }
                          >
                            Remove
                          </RightSideRemoveLabel>
                        </MemberCardRightData>
                      </MemberCard>
                    );
                  })
                )}
              </div>
            </Box>

            <PlusGroupContainer>
              <PlusGroup
                onClick={(_) => this.handleAddMoreInviteMember()}
                data-test-id="data-add-more-data-id"
              >
                <ControlPoint
                  style={{ color: "#237182", fontFamily: "poppins" }}
                />
                <PlusText style={{ fontFamily: "poppins" }}>
                  Invite another member
                </PlusText>
              </PlusGroup>

              <Box style={webStyle.modalInviteButtons}>
                <RenameButton
                  style={{...webStyle.cancelButton,marginRight:"16px"}}
                  data-test-id="cancel-invited-data-id"
                  onClick={() => this.handleInviteMember()}
                >
                  Cancel
                </RenameButton>
                <RenameButton
                  onClick={this.handleAllInvitedMemberSubmit}
                  data-test-id="invite-send-btn"
                >
                  Invite
                </RenameButton>
              </Box>
            </PlusGroupContainer>
          </MemberCardContainer>
        </Box>
      </Modal>
    );
  };

  renderErrorMessage() {
    const { memberEmailInviteError, emailFormatInviteError, inviteMemberError } =
      this.state;
    if (memberEmailInviteError) {
      return (
        <Typography style={webStyle.errorText}>{memberEmailInviteError}</Typography>
      );
    }

    if (emailFormatInviteError) {
      return (
        <Typography style={webStyle.errorText}>{emailFormatInviteError}</Typography>
      );
    }

    if (inviteMemberError) {
      return (
        <Typography style={webStyle.errorText}>{inviteMemberError}</Typography>
      );
    }

    return null;
  }




  renderMembersList = () => {
    const { searchList } = this.state;
    if (
      searchList &&
      searchList.length > 0 &&
      this.state.membersList.length !== 0
    ) {
      return searchList.map((row, index) => (
        <TableRow key={index}>
          <TableCell
            className="SubDivC"
            style={{ fontFamily: "Poppins" }}
          >{`${row.attributes.first_name} ${row.attributes.last_name}`}</TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            {row.attributes.email}
          </TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            {row.attributes.job_title}
          </TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            <Typography
              style={{
                height: "20px",
                width: "fit-content",
                borderRadius: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBlock: "2px",
                paddingInline: "8px",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                fontFamily: "Poppins",
                ...this.getRoleStyles(row.attributes.role_id),
              }}
            >
              {row.attributes.role_id}
            </Typography>
          </TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            {row.attributes.invited_status ? "Activated" : "Not Activated"}
          </TableCell>

         {this.state.userRole === "employee" ? "" :  <UpdatedBox>
            <TableCell style={{ justifyContent: "end" }}>
              <Typography
                data-test-id="hanlde-click-id"
                className="hoverEffect"
                onClick={(event) => this.handleClick(event, row)}
              >
                <MoreVertIcon style={{ cursor: "pointer" }} />
              </Typography>

              <CustomPopover
                style={webStyle.popup}
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                data-test-id="handleClose-id"
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    padding: "4px",
                    marginLeft: "-3rem",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px 0px #00000008",
                    border:'1px solid #E2E8F0'
                  },
                }}
              >
                <MenuItem
                  style={{
                    ...webStyle.popMenu,
                    marginBottom: "10px",
                  }}
                  data-test-id="edit-team-data-id"
                  onClick={() => this.handleEditDetailModal()}
                >
                  {" "}
                  <span style={webStyle.iconM}>
                    <EditIcon style={{width:'18px',height:'18px'}}/>
                  </span>{" "}
                  Edit details
                </MenuItem>
                <MenuItem
                  style={{
                    ...webStyle.popMenu,
                    marginBottom: "10px",
                  }}
                  data-test-id="rename"
                  onClick={() => this.handleMoveMemberToOtherTeam()}
                >
                  <span style={webStyle.iconM}>
                    <img src={arrows} alt="rename" />
                  </span>{" "}
                  Move Member
                </MenuItem>
                <MenuItem
                  style={webStyle.deleteIcon}
                  data-test-id="delete"
                  onClick={() => {
                    this.handleRemoveMember();
                  }}
                >
                  <span style={webStyle.iconM}>
                    <img src={accountDel} alt="accountDel" style={{width:'18px', height:'16px'}} />
                  </span>
                  Remove Member
                </MenuItem>
              </CustomPopover>
            </TableCell>
          </UpdatedBox>}
        </TableRow>
      ));
    } else
      return (
        <TableRow>
          <TableCell
            colSpan={5}
            style={{ textAlign: "center", fontFamily: "Poppins" }}
          >
            No members found
          </TableCell>
        </TableRow>
      );
  };

  renderDeleteTeamModal = () => {
    return (
      <Modal
        open={this.state.isDeleteMemberModalOpen}
        data-test-id="handleDeleteModal"
        onClose={() => this.handleRemoveMember()}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.renameModalDelete}>
          <Box style={webStyle.modalHeaderDelete}>
            <Typography style={webStyle.renameText}>
              Are you sure you want to remove{" "}
              {`${this.state.selectedRow?.attributes.first_name} ${
                this.state.selectedRow?.attributes.last_name ?? ""
              }`.trim()}{" "}
              from this team?
            </Typography>
            <Close
              onClick={() => this.handleRemoveMember()}
              style={{ cursor: "pointer" }}
              data-test-id="close-btn"
            />
          </Box>
          <Typography style={{ color: "#1E293B", width: "90%" }}>
            Removing{" "}
            {` ${this.state.selectedRow?.attributes.first_name} ${this.state.selectedRow?.attributes.last_name} `.trim()}{" "}
            from the team will revoke their access and permissions within the
            team.
          </Typography>

          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={() => this.handleRemoveMember()}
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
              onClick={this.handleRemoveMemberApi}
              data-test-id="removeMember-inviter-data-id"
            >
              Remove
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderModal() {
    return (
      <Modal open={this.state.isAddMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={{...webStyle.modalHeader, marginBottom: "8px"}}>
            <Typography style={webStyle.renameText}>
              Add Members to Your Team
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Add team members by entering their email addresses below.
          </Typography>
       
  <CustomSelect data-test-id="memberError"  memberError={this.state.memberError} onSelectionChange={(selectedUsers) => this.setState({ selectedUsers })} membersData={this.state.users}
   onMemberErrorClear={() => this.setState({ memberError: '' })}
          ref={(ref) => console.log("Props passed to CustomSelect:", this.state.users)} selectedOptions={[]} navigation={undefined} id={""}/>
 
{this.state.userRole === "owner" ?<Box style={webStyle.inviteMemberStyle}>
         <Box style={{fontWeight:400,fontSize:"16px",fontFamily:"Poppins" }} >or</Box>
         <MailOutlineIcon style={{color:"#237182"}}/> 
        
        <Box style={{color:"#237182" ,fontWeight:600,fontSize:"16px",fontFamily:"Poppins" , cursor:"pointer"}}         onClick={()=>{this.handleEditDetailInviteModal()}}
          data-test-id="new-invite-member-data-id"
          >Invite New Members</Box>
                    </Box>:""}
  <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={() => this.handleAddMemberModal()}      
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
             onClick={this.handleAddMemberData}
              data-test-id="removeMember-data-id"
              disabled={this.state.selectedUsers.length === 0} 
            >
              Add
            </RenameButton>
          </Box>

        </Box>
      </Modal>
    );
  }

  renderRenameTeamModal = () => {
    return (
      <Modal
        data-test-id="rename-close-modal-id"
        onClose={() => this.handleRenameModal()}
        open={this.state.isRenameModalOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.renameModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Rename Team</Typography>
            <Close
              data-test-id="rename-team-modal-click-id"
              onClick={() => this.handleRenameModal()}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Choose a new name for your team to help organize members
            effectively.
          </Typography>
          <Box mt="1.5rem">
            <InputLabel style={webStyle.renameInputLabel}>Team Name</InputLabel>
            <RenameTextField
              value={this.state.teamRename}
              error={
                this.state.teamRenameBlankError ||
                this.state.teamNameRenameError
              }
              variant="outlined"
              name="teamName"
              data-test-id="handleteamName-id"
              placeholder="Enter team name"
              onChange={(e) => this.handleTeamNameChange(e.target.value)}
              fullWidth
              required
              inputProps={{
                maxLength: 50,
                style: {
                  fontFamily: "'Poppins', sans-serif",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
              }}
            />
            {this.state.teamRenameBlankError && (
              <Typography style={webStyle.errorText}>
                {" "}
                The team name cannot be blank.
              </Typography>
            )}
            {this.state.teamNameRenameError && (
              <Typography style={webStyle.errorText}>
                {" "}
                This team name is already in use. Please choose a different
                name.
              </Typography>
            )}
          </Box>
          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              data-test-id="cancel-id"
              onClick={() => this.handleRenameModal()}
            >
              {" "}
              Cancel
            </RenameButton>
            <RenameButton
              disabled={!this.state.teamName}
              onClick={this.editTeamNameApi}
              data-test-id="rename-btn-click-data-id"
            >
              Rename
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderActivatedCheckbox = () => {
    const { isApproveOpen, activated } = this.state;
    return (
      <CustomCheckbox
        data-test-id="approve-checkbox"
        open={
          isApproveOpen
        }
        onOpen={() => this.setState(
          { isApproveOpen: true }
        )
      }
        onClose={() => this.setState(
          { isApproveOpen: false }
        )
      }
        IconComponent={() =>
           {
          const iconStyle = this.state.activated.length > 0
          ? { display: this.state.activated.length>0 ? 
            "none" : "block"
           } 
          : { display: "block" 

          };
          return this.state.isApproveOpen ?
           (
            <ExpandLess
              data-test-id="expandLess"
              onClick={() => this.setState(
                { isApproveOpen: false }
              )}
              style={iconStyle} />
          ) : (
            <ExpandMore
              data-test-id="expandMore"
              onClick={() => this.setState({ 
                isApproveOpen: true 
              })
            }
              style={iconStyle}
            />
          );
        }
      }
        style={
          {
          width: "197px",
          display: "flex",
          alignItems: "center",
        }
      }    
          multiple
        value={
          activated}
        onChange={this.handleChange}
        name="activated"
        variant="outlined"
        placeholder="Activated"
        displayEmpty
        renderValue={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Activated</span>
            {this.state.activated.length > 0 && 
            (
              <>
                <KeyboardArrowDownIcon style={
                  { marginLeft: "10px", display: "block" }
                  } 
                  />
                <span
                  style={
                    {
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#237182",
                    color: "white",
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginLeft: "32px", 
                    padding: "10px",
                    textAlign:"center"
                  }
                }
                >
                  {
                  this.state.activated.length
                  }
                </span></>
            )
            }
          </div>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left", },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",  },
          getContentAnchorEl: null,
          PaperProps: {
            style:
             {
              width: 197,
              maxHeight: "400px",
              overflowY: "auto",
              textAlign: "center",
              borderRadius: "8px",
              fontSize: "14px",
              fontFamily: "poppins",
              lineHeight: "22px",
              fontWeight: 400,
            },},
        }
      }>
        {["Activated", "Not Activated"].map((activate) => 
        (
          <CustomListItem key={activate} value={activate}>
            <Checkbox
              icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
              checkedIcon={
                <img 
                src={checkedIcon} 
                style={{ width: 16, height: 16 }} />
              }
              checked={activated.indexOf(activate) > -1}
            />
            <ListItemText primary={activate} 
            primaryTypographyProps={{ style: { fontSize: "14px" } }}
            />
          </CustomListItem>
        ))}
        <CustomListItem value="clear">
                  <ListItemText
                    primaryTypographyProps={{
                      style: webStyle.clearAll,
                    }}
                  >
                    Clear All
                  </ListItemText>
                </CustomListItem>
      </CustomCheckbox>
    );
  };

  renderEditDetailModal = () => {
    return (
      <Modal
        data-test-id="edit-data-id"
        onClose={() => this.handleEditDetailModal()}
        open={this.state.isEditDetailModalOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.editModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Member Details</Typography>
            <Close
              data-test-id="modeling"
              onClick={() => this.handleEditDetailModal()}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Box>
            <FirstAndLastNameInputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    placeholder="Enter first name"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberFirstName}
                    name="memberFirstName"
                    data-test-id="inputchange"
                    onChange={(e) => {
                      this.handleInputDataChange(e);
                    }}
                    error={!!this.state.memberFirstNameError}
                    InputProps={{
                      style: inputStyles,
                      inputProps: {
                        maxLength: 50,
                        style: placeholderStyles,
                      },
                    }}
                  />
                  {this.state.memberFirstNameError && (
                    <Typography style={webStyle.errorText}>
                      The First Name cannot be blank.
                    </Typography>
                  )}
                </Box>
              </InputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    placeholder="Enter last name"
                    size="medium"
                    variant="outlined"
                    data-test-id="txtInputLastName"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberLastName}
                    name="memberLastName"
                    onChange={(e) => {
                      this.handleInputDataChange(e);
                    }}
                    error={!!this.state.memberLastNameError}
                    InputProps={{
                      style: inputStyles,
                      inputProps: {
                        maxLength: 50,
                        style: placeholderStyles,
                      },
                    }}
                  />
                  {this.state.memberLastNameError && (
                    <Typography style={webStyle.errorText}>
                      The Last Name cannot be blank.
                    </Typography>
                  )}
                </Box>
              </InputGroup>
            </FirstAndLastNameInputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  placeholder="Enter email"
                  size="medium"
                  variant="outlined"
                  data-test-id="txtInputEmail"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={this.state.memberEmail}
                  name="memberEmail"
                  onChange={(e) => {
                    this.handleInputDataChange(e);
                  }}
                  error={
                    !!this.state.memberEmailError ||
                    !!this.state.emailFormatError
                  }
                  InputProps={{
                    style: inputStyles,
                    inputProps: {
                      maxLength: 50,
                      style: placeholderStyles,
                    },
                  }}
                />
                {this.state.memberEmailError && (
                  <Typography style={webStyle.errorText}>
                    The email cannot be blank.
                  </Typography>
                )}
                {this.state.emailFormatError && (
                  <Typography style={webStyle.errorText}>
                    The email should be in format.
                  </Typography>
                )}
              </Box>
            </InputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Job Title
                </Typography>
                <TextField
                  placeholder="Enter job title"
                  size="medium"
                  variant="outlined"
                  data-test-id="txtInputJobTitle"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={this.state.jobTitle}
                  name="jobTitle"
                  onChange={(e) => {
                    this.handleInputDataChange(e);
                  }}
                  error={!!this.state.jobTitleError}
                  InputProps={{
                    style: inputStyles,
                    inputProps: {
                      maxLength: 50,
                      style: placeholderStyles,
                    },
                  }}
                />
                {this.state.jobTitleError && (
                  <Typography style={webStyle.errorText}>
                    The Job Title cannot be blank.
                  </Typography>
                )}
              </Box>
            </InputGroup>
            <InputGroup>
              <Typography
                style={{
                  color: "#334155",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {" "}
                Role
              </Typography>
              <RoleDiv>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "owner",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="owner-role"
                  onClick={() => {
                    this.handleRoleClick("owner");
                  }}
                >
                  {" "}
                  Owner
                </Button>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "manager",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="manager-role"
                  onClick={() => {
                    this.handleRoleClick("manager");
                  }}
                >
                  Manager
                </Button>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "employee",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="employee-role"
                  onClick={() => {
                    this.handleRoleClick("employee");
                  }}
                >
                  {" "}
                  Employee
                </Button>
              </RoleDiv>
            </InputGroup>
          </Box>

          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              data-test-id="edit-id"
              onClick={() => this.handleEditDetailModal()}
            >
              Cancel
            </RenameButton>
            <RenameButton onClick={this.handleSubmit} data-test-id="edit-data-submit-btn">
              Save
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderMoveMemberToOtherTeam = () => {
    return (
      <Modal
        data-test-id="move-data-id"
        onClose={() => this.handleMoveMemberToOtherTeam()}
        open={this.state.isMoveMemberModalOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.renameModalDelete as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>
              Move Team Member
            </Typography>
            <Close
              data-test-id="move-click-id"
              onClick={() => this.handleMoveMemberToOtherTeam()}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Select one or more teams to move the member to.
          </Typography>
          <Box mt="1.5rem" style={webStyle.InputSearchMove} position="relative">
            <Box style={{ fontFamily: "Poppins" }}>Search Teams</Box>
            <style>
              {`
      .basic-single-select {
        width: 100%;
      }
      .css-g1d714-ValueContainer {
        padding-left: 20px; 
      }
        .select__single-value {
  font-family: Poppins, sans-serif !important;
}
    `}
            </style>
            <Select
              value={this.state.teamNameTitle}
              name="team"
              options={this.state.teamData.map((team) => ({
                value: team.id,
                label: team.teamName,
              }))}
              className="basic-single-select"
              classNamePrefix="select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "10px 8px",
                  border: this.state.moveMemberError
                    ? "2px solid red"
                    : "1px solid #ccc",
                  borderRadius: "8px",
                  paddingLeft: "1rem",
                  boxShadow: "none",
                  "&:hover": {
                    border: this.state.moveMemberError
                      ? "2px solid red"
                      : "1px solid #ccc",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#0F172A",
                  lineHeight: "24px",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  marginLeft: "1rem",
                  "!important": true,
                }),
                input: (provided) => ({
                  ...provided,
                  margin: 0,
                  padding: "0 6px",
                  width: "auto",
                  flexGrow: 1,
                  paddingLeft:'14px'
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontFamily: "Poppins",
                  paddingLeft: "14px",
                }),
                menu: (provided) => ({
                  ...provided,
                  fontFamily: "Poppins", 
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontFamily: "Poppins", 
                  backgroundColor: "white",
                  color: "#0F172A",
                  "&:hover": {
                    backgroundColor: "#E5E7EB",
                    color: "#1F2937",
                  },
                }),

              }}
              components={{
                ClearIndicator: (props) => (
                  <components.ClearIndicator {...props}>
                    <div style={{ cursor: "pointer", marginLeft: "8px" }}>
                      ✕
                    </div>
                  </components.ClearIndicator>
                ),
                DropdownIndicator: () => null,
              }}
              isClearable
              placeholder="Type to search for a team..."
              onChange={(selectedOption) => {
                this.setState({
                  teamNameTitle: selectedOption,
                  moveMemberError: selectedOption
                    ? ""
                    : this.state.moveMemberError,
                });
              }}
              onFocus={this.fetchTeamsApi}
              onInputChange={(inputValue) => {
                if (inputValue.length <= 50) {
                  this.handleInputChange(inputValue);
                }
              }}
              inputValue={this.state.inputName}
              data-test-id="seleted-id"
            />

            <SearchIcon
              style={{
                transform: "translateY(-50%)",
                color: "grey",
                position: "absolute",
                left: "2%",
                top: "70%",
              }}
            />
          </Box>

          {this.state.moveMemberError && (
            <Box
              color="red"
              mt="0.5rem"
              style={{ fontFamily: "poppins" }}
              data-test-id="error-message"
            >
              {this.state.moveMemberError}{" "}
            </Box>
          )}

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              marginTop: "1rem",
            }}
          >
            <Box style={{ fontFamily: "poppins" }}>Or</Box>
            <Box
              style={webStyle.moveText}
              data-test-id="new-create"
              onClick={this.handleCreateTeamModal}
            >
              Create New Team
            </Box>
          </Box>

          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              data-test-id="move-id"
              onClick={() => this.handleMoveMemberToOtherTeam()}
            >
              Cancel
            </RenameButton>
            <RenameButton
              style={{
                backgroundColor:
                  this.state.teamNameTitle || null ? "#237182" : "#F1F5F9",
                color: this.state.teamNameTitle || null ? "#FFFFFF" : "#64748B",
              }}
              onClick={this.handleMoveMemberSubmit}
              data-test-id="move-member-data-btn"
              disabled={!this.state.teamNameTitle}
            >
              Move Member
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderCreateTeamModal = () => {
    return (
      <Modal
        open={this.state.isCreateTeamModalOpen}
        onClose={this.handleCreateTeamModal}
        style={{ display: "flex" }}
        data-test-id="modal-data-id"
      >
        <Box style={webStyle.renameModalDelete}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Create New Team</Typography>
            <Close
              data-test-id="closeBox-data-id"
              onClick={() => this.handleCreateTeamModal()}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Enter the name for your team to help organize members effectively.
          </Typography>
          <Box mt="1.5rem">
            <InputLabel style={webStyle.renameInputLabel}>Team name</InputLabel>
            <RenameTextField
              style={{
                paddingTop: "6px",
              }}
              error={!!this.state.errorData}
              value={this.state.teamNameData}
              variant="outlined"
              name="teamNameData"
              data-test-id="teamNameData"
              onChange={(e) => this.handleCreateTeamName(e.target.value)}
              fullWidth
              placeholder="Enter team name"
              required
              inputProps={{
                maxLength: 50,
                style: {
                  fontFamily: "'Poppins', sans-serif",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
              }}
            />
            {this.state.errorData && (
              <Typography style={webStyle.errorText}>
                {this.state.errorData}
              </Typography>
            )}
          </Box>
          <Box style={webStyle.modalButtons}>
            <RenameButton
              data-test-id="cancelBtnData-id"
              style={webStyle.cancelButton}
              onClick={() => this.handleCreateTeamCancelButton()}
            >
              Cancel
            </RenameButton>
            <RenameButton
              disabled={!this.state.teamNameData}
              data-test-id="create-team-id"
              onClick={this.handleCreateTeamSubmit}
            >
              Create
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderDataFirst = () => {
    return (
      <Box style={{ display: "flex",
    flexDirection: "column",
    minHeight: "90vh",}}>
        <Box data-test-id="txtInput" style={webStyle.flex}>
          <Box style={webStyle.mainContainer}>
            <Box style={webStyle.TopContainer}>
            <Button
  data-test-id="back-page"
  style={{ ...webStyle.BackBtn, padding: "6px 0px" }}
  onClick={this.backBtn}
>
  <span style={{ fontSize: "20px", verticalAlign: "middle" }}>&#60;</span>
  <span style={{ paddingLeft: "8px", textTransform: "capitalize" }}>Back</span>
</Button>
              <Box style={webStyle.TeamName}>
                {this.state.teamName}
               {this.state.userRole === "employee" ? "" :  <IconButton
                  data-test-id="add-team-id"
                  onClick={() => {
                    this.handleRenameModal();
                  }}
                >
                  <EditIcon />
                </IconButton>}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography style={webStyle.teamSize}>
                {this.state.totalMembers + " Members"}
              </Typography>
            {this.state.userRole === "employee" ? "" :   <Button
                data-test-id="btnAddExample"
                onClick={this.handleOpenModal}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#237182",
                  color: "white",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: "16px",
                  borderRadius: "8px",
                  padding: "10px 16px 10px 16px",
                  textTransform: "capitalize",
                }}
              >
                <span style={webStyle.createIcon}>
                  <img src={createIcon} alt="createIcon" />
                </span>
                Add Members
              </Button>}
            </Box>
          </Box>
        </Box>
        <Box style={webStyle.flex}>
          <Box style={webStyle.searchBox}>
            <TextField
              variant="outlined"
              placeholder="Search here..."
              data-test-id="search-input"
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 50) {
                  this.getMembersListSelectApi(inputValue);
                }
              }}
              inputProps={{
                maxLength: 50,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{
                        color: "#94A3B8",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: this.state.searchValue && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.clearSearch}
                      data-test-id="clear-id"
                      disableRipple
                      style={{
                        padding: 0,
                        cursor: "pointer",
                      }}
                    >
                      <ClearIcon
                        style={{
                          color: "#94A3B8",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  width: "440px",
                  height: "32px",
                  borderRadius: "8px",
                },
              }}
              style={{
                marginRight: "12px",
                backgroundColor: "white",
                height: "32px",
                borderRadius: "8px",
              }}
            />

            <Box style={{ display: "flex", gap: "12px" }}>
        

              {this.renderJobTitleCheckbox()}

              <CustomCheckbox
                data-test-id="role-checkbox"
                open={this.state.isRoleOpen}
                onOpen={() => this.setState({ isRoleOpen: true })}
                onClose={() => this.setState({ isRoleOpen: false })}
                IconComponent={() => {
                  const iconStyle = this.state.selectedRoles.length > 0
                  ? { display: this.state.selectedRoles.length>0 ? "none" : "block" } 
                  : { display: "block" };
                  return this.state.isRoleOpen ? (
                    <ExpandLess
                      data-test-id="expandLess"
                      onClick={() => this.setState({ isRoleOpen: false })}
                      style={iconStyle}
                    />
                  ) : (
                    <ExpandMore
                      data-test-id="expandMore"
                      onClick={() => this.setState({ isRoleOpen: true })}
                      style={iconStyle}
                    />
                  );
                }}
                multiple
                name="role"
                value={this.state.selectedRoles}
                onChange={this.handleChange}
                variant="outlined"
                displayEmpty
                placeholder="Role"
                renderValue={() => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Role</span>
                    {this.state.selectedRoles.length > 0 && (
                      <>
                        <KeyboardArrowDownIcon style={{ marginLeft: "10px", display: "block" }} />
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#237182",
                            color: "white",
                            width: "4px",
                            height: "4px",
                            borderRadius: "50%",
                            fontSize: "12px",
                            fontWeight: 600,
                            marginLeft: "30px", 
                            padding: "10px",
                            textAlign:"center"
                          }}
                        >
                          {this.state.selectedRoles.length}
                        </span>
                      </>
                    )}
                  </div>
                )}
                style={{
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      width: 150,
                      maxHeight: "400px",
                      overflowY: "auto",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      lineHeight: "22px",
                      textAlign: "center",
                      fontWeight: 400,
                    },
                  },
                }}
                
              >
                {["Owner", "Manager", "Employee"].map((role) => (
                  <CustomListItem key={role} value={role}>
                    <Checkbox
                      icon={
                        <img
                          src={unCheckIcon}
                          style={{ width: 16, height: 16 }}
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkedIcon}
                          style={{ width: 16, height: 16 }}
                        />
                      }
                      style={{ color: "#64748B" }}
                      checked={this.state.selectedRoles.indexOf(role) > -1}
                    />
                    <ListItemText primary={role}
                    primaryTypographyProps={{ style: { fontSize: "14px" } }}

                     />
                  </CustomListItem>
                ))}
                <CustomListItem value="clear">
                  <ListItemText
                    primaryTypographyProps={{
                      style: webStyle.clearAll,
                    }}
                  >
                    Clear All
                  </ListItemText>
                </CustomListItem>
              </CustomCheckbox>

              {this.renderActivatedCheckbox()}
            </Box>
          </Box>

          <Box
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0",
              }}
            >
              <Box style={webStyle.sort}>
                {" "}
                <Typography variant="body1" style={{ fontFamily: "Poppins" }}>
                  Sort By:
                </Typography>
              </Box>
              <Box>
                <select
                  style={{
                    border: "none",
                    color: "#237182",
                    fontWeight: 600,
                    fontSize: "16px",
                    backgroundColor: "#f0f5f7",
                    marginBottom: "2px",
                    marginRight: "5px",
                  }}
                  name="sort"
                  data-test-id="sort-data-id"
                  value={this.state.selectedOrder[0]}
                  onChange={this.handleChange}
                >
                  <option value="a-z">A-Z</option>
                  <option value="z-a">Z-A</option>
                </select>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={webStyle.mt10}>
          <TableContainer style={webStyle.SubTableBox}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={webStyle.TableTitleText}>Name</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Email</TableCell>
                  <TableCell style={webStyle.TableTitleText}>
                    Job Title
                  </TableCell>
                  <TableCell style={webStyle.TableTitleText}>Role</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Status</TableCell>
                  {this.state.userRole === "employee" ? "" :<TableCell style={webStyle.TableTitleText}></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>{this.renderMembersList()}</TableBody>
            </Table>
          </TableContainer>
        </Box>

              <Box
          sx={{
            position: "relative", 
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            padding: "8px 16px",
          }}
        >
          {this.renderPagination()}
        </Box>

      </Box>
    );
  };


  renderJobTitleCheckbox = ( )=>{
    return(
      <CustomCheckbox
                data-test-id="job-checkbox"
                open={this.state.isJobTitleOpen}
                onOpen={() => this.setState({ isJobTitleOpen: true })}
                onClose={() => this.setState({ isJobTitleOpen: false })}
                IconComponent={() => {
                  const iconStyle = this.state.selectedJobTitle.length > 0
                  ? { display: this.state.selectedJobTitle.length>0 ? "none" : "block" } 
                  : { display: "block" };
                  return this.state.isJobTitleOpen ? (
                    <ExpandLess
                      data-test-id="expandLess"
                      onClick={() => this.setState({ isJobTitleOpen: false })}
                      style={iconStyle}
                    />
                  ) : (
                    <ExpandMore
                      data-test-id="expandMore"
                      onClick={() => this.setState({ isJobTitleOpen: true })}
                      style={iconStyle}
                    />
                  );
                }}
                style={{
                  width: "155px",
                  display: "flex",
                  alignItems: "center",
                }}
                multiple
                value={this.state.selectedJobTitle}
                renderValue={() => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Job Title</span>
                    {this.state.selectedJobTitle.length > 0 && (
                      <>
                        <KeyboardArrowDownIcon style={{ marginLeft: "10px", display: "block" }} />
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#237182",
                            color: "white",
                            width: "4px",
                            height: "4px",
                            borderRadius: "50%",
                            fontSize: "12px",
                            fontWeight: 600,
                            marginLeft: "3px", 
                            padding: "10px",
                            textAlign:"center"
                          }}
                        >
                          {this.state.selectedJobTitle.length}
                        </span>
                      </>
                    )}
                  </div>
                )}
                name="job"
                onChange={this.handleChange}
                variant="outlined"
                placeholder="Job Title"
                displayEmpty
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      width: 155,
                      maxHeight: "400px",
                      overflowY: "auto",
                      borderRadius: "8px",
                      fontSize: "14px",
                      textAlign: "center",
                      fontWeight: 400,
                      fontFamily: "poppins",
                      lineHeight: "22px", },
                  },
                }
              }>
                {this.state.jobTitles.length === 0 ? 
                (
                  <CustomListItem value="no-titles">
                    <ListItemText
                      primary={
                        <span
                          style={
                            {
                            display: "inline-block",
                            maxWidth: "calc(100% - 24px)",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "210px",
                            padding: "0rem 1rem",
                          }
                        }
                          title="No job titles available"
                        >
                          {
                          "No Job Titles available".slice(0, 25)
                          }...
                        </span>
                      }/>
                  </CustomListItem>
                ) : (
                  this.state.jobTitles.map((job: string) => (
                    <CustomListItem key={job} value={job}>
                      <Checkbox
                        icon={
                          <img
                            src={unCheckIcon}
                            style={{ width: 16, height: 16 }}
                          />
                        }
                        checkedIcon={
                          <img
                            src={checkedIcon}
                            style={{ width: 16, height: 16 }}
                          />
                        }
                        checked={this.state.selectedJobTitle.includes(job)}
                      />
                      <ListItemText
                        primary={
                          <span
                            style={{
                              display: "inline-block",
                              width: "calc(100% - 24px)",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={job}
                          >
                            {job}
                          </span>
                        }
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}

                      />
                    </CustomListItem>
                  ))
                )}

                {/* Clear all option */}
                {this.state.jobTitles.length > 0 && (
                  <CustomListItem value="clear">
                    <ListItemText
                      primaryTypographyProps={{
                        style: webStyle.clearAll,
                      }}
                    >
                      Clear All
                    </ListItemText>
                  </CustomListItem>
                )}
              </CustomCheckbox>
    )
  }

  generatePageNumbers = (
    currentPage: number,
    totalPages: number,
    maxVisiblePages: number
  ) => {
    if (totalPages <= maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 2) {
      return [1, 2, "...", totalPages];
    }

    if (currentPage >= totalPages - 1) {
      return [1, "...", totalPages - 1, totalPages];
    }

    return [
      1,
      "...",
      currentPage - 1,
      currentPage,
      currentPage + 1,
      "...",
      totalPages,
    ];
  };

  renderPagination = () => {
    const { rowsPerPage, currentPage, totalRecords } = this.state;
    const totalPages = Math.ceil(totalRecords / rowsPerPage);
    const maxPageButtons = 8;

    const pageNumbers = this.generatePageNumbers(
      currentPage,
      totalPages,
      maxPageButtons
    );
    const handleClickData = (page: any) => {
      if (page !== "...") this.setPage(page);
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
      <Box sx={webStyle.footer}>
        <Box sx={webStyle.footer2}>
          <Box></Box>
          <Box style={webStyle.footer3}>
            <ChevronLeftIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isFirstPage ? "#ccc" : "inherit",
                cursor: isFirstPage ? "default" : "pointer",
              }}
              data-test-id="pagination-click-id"
              onClick={!isFirstPage ? this.handlePreviousPage : undefined}
            />
            {pageNumbers.map((page, index) => 
            (
              <Box key={index}
               sx={{ marginLeft: "8px", marginRight: "8px" }}>
                <Typography
                  style={
                    {
                    ...webStyle.footerFont,
                    cursor: page === "..." ? 
                    "default" : "pointer",
                    fontWeight: page === currentPage ? 
                    "bold" : "normal",
                  }
                }
                  data-test-id="page-data-id"
                  onClick={
                    () => handleClickData(page)
                  }
                >
                  {page}
                </Typography>
              </Box>
            ))}
            <ChevronRightIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isLastPage ? "#ccc" : "inherit",
                cursor: isLastPage ? "default" : "pointer",
              }}
              onClick={!isLastPage ? this.handleNextPage : undefined}
            />
          </Box>
          <Typography style={{fontSize:"12px" , fontFamily:"Poppins"}}>
            {`${(currentPage - 1) * rowsPerPage + 1} - ${Math.min(
              currentPage * rowsPerPage,
              totalRecords
            )} of ${totalRecords} results`}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderDataSecond = () => {
    return (
      <Box>
        <Box data-test-id="txtInput" style={webStyle.flex}>
          <Box style={webStyle.mainContainer}>
            <Box style={webStyle.TopContainer}>
            <Button
  data-test-id="backed-page"
  style={webStyle.BackBtn}
  onClick={this.backBtn}
>
  <Box style={{ fontSize: "20px", display: "inline", verticalAlign: "middle" }}>&#60;</Box>
  &nbsp;
  <Box style={{ display: "inline", textTransform: "capitalize" }}>Back</Box>
</Button>


              <Box style={webStyle.TeamName}>
                <Box> {this.state.historyLocationState}</Box>
               {this.state.userRole === "employee" ? "" :  <IconButton
                  data-test-id="second-rename-id"
                  onClick={() => {
                    this.handleRenameModal();
                  }}
                >
                  <EditIcon />
                </IconButton>}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.cardStyle}>
          <CardMedia component="img" style={webStyle.image} image={image} />
          <Box sx={webStyle.CardContent}>
          {this.state.userRole === "employee" ? <Box>  <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>
              No Members Present
            </Box>
            <Box style={{marginTop:"8px", textAlign: "center", fontFamily: "Poppins" }}>
              COntact owner or manager to add members.
            </Box></Box>: <Box>  <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>
              No Members Added
            </Box>
            <Box style={{ marginTop:"8px",  textAlign: "center", fontFamily: "Poppins" }}>
              Add members to start collaborating.
            </Box></Box> }
         {this.state.userRole === "employee" ? "" :   <Button
              data-test-id="btnAddExample"
              onClick={this.handleOpenModal}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#237182",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                padding: "10px 16px 10px 16px",
                textTransform: "capitalize",
              }}
            >
              <span style={webStyle.createIcon}>
              <GroupAddOutlinedIcon style={{ width: '24px', height: '24px' }} />
              </span>
              Add Members
            </Button>}
          </Box>
        </Box>
      </Box>
    );
  };

  renderCreateTeamModal2 = () => {
    return (
      <Box style={{ background: "rgb(240, 245, 247)" }}>
        {this.state.membersList.length === 0 && !this.state.isAddNot ? (
          <Box>{this.renderDataSecond()}</Box>
        ) : (
          <Box>{!this.state.loading ? <>{this.renderDataFirst()}</> : ""}</Box>
        )}

        <Box></Box>
      </Box>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box style={{ background: "#f0f6f7", height: "100%" }}>
          <MyProfileHeader navigation={this.props.navigation}>
            {!!this.state.messageToggle && (
              <MessageBox
                data-test-id="closeBox-id"
                message={this.state.tostifymessage}
                status={this.state.status}
              />
            )}
            <Loader loading={this.state.loading} />
            {this.renderCreateTeamModal2()}
            {this.renderModal()}
            {this.renderRenameTeamModal()}
            {this.renderDeleteTeamModal()}
            {this.renderEditDetailModal()}
            {this.renderMoveMemberToOtherTeam()}
            {this.renderCreateTeamModal()}
            {this.renderInviteMember()}
            {this.renderInvitedMemberList()}
            <CustomToast icon={<img src={success} />}
              closeButton={false}
              />
          </MyProfileHeader>
        </Box>
      </ThemeProvider>
    );
  }
}
const PlusText = styled("div")({
  color: "#237182",
  fontWeight: 700,
  fontSize: "18px",
});


const PlusGroupContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "16px",
});

const PlusGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
});

const MemberCardLeftData = styled("div")(
  {
  width: "80%",
  padding: "16px 0px 16px 24px",
}
);
const MemberCardRightData = styled("div")(
  {
  width: "20%", display: "flex",
  flexDirection: "column",
 
  gap: "2rem",
  alignItems: "center",
});
const CardNameLabel = styled("div")({
  fontSize: "16px",
  fontWeight: 600,
  color: "#0F172A",
  paddingBottom: "6px",
  fontFamily: "Poppins",
});
const CardEmailLabel = styled("div")({
  fontSize: "14px",
  
  marginTop: "6px",
  fontWeight: 400,
  color: "#475569",
  fontFamily: "Poppins",
});
const RightSideLabel = styled("div")({
  color: "#325962",
  backgroundColor: "#E0EDF0",
  fontWeight: 400,
  padding: "4px 8px",
  borderRadius: "16px",
  fontSize: "12px",
  
  fontFamily: "poppins",
});
const RightSideRemoveLabel = styled("div")({
  color: "#DC2626",
  fontSize: "12px",
  marginRight: "4px",
  cursor: "pointer",
  fontFamily: "poppins",
  fontWeight: 600,
 
});

const MemberCard = styled("div")(
  {
  width: "100%",
 
  borderLeft: "4px solid #237182",
  borderRadius: "6px",
  backgroundColor: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  marginBlock: "1rem",
  boxShadow: "0px 2px 8px 0px #00000014",
}
);
const MemberCardContainer = styled("div")(
  {
  width: "100%",
  marginTop: "28px",
  flexDirection: "column",
  display: "flex", 
  justifyContent: "space-between",
  "@media(max-width:1245px)": {},
});

const CustomToast = styled(ToastContainer)({
  width: "unset",
  marginTop:'80px',
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.3rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
  "& .Toastify__close-button": {
    display: "none", 
  },
});

const UpdatedBox = styled(Box)({
  "& .hoverEffect": {
    padding: "3px",
    "&:hover": {
      backgroundColor: "#D3E4E9",
      borderRadius: "50%",
    },
  },
});

const CustomCheckbox = styled(MUISelect)({
  borderRadius: "8px",
  height: "32px",
  minWidth: "123px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "7px",
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-input": {
    padding: "7.5px 14px",
  },
});

const CustomListItem = styled(MenuItem)({
  "&.Mui-selected": {
    backgroundColor: "transparent",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#E0EDF0",
  },
  "&:hover": {
    backgroundColor: "#E0EDF0",
  },
  "&.MuiMenuItem-root": {
    paddingLeft: "0px",
    paddingRight: "8px",
  },
});

const CustomPopover= styled(Popover)({
  '& .MuiListItem-button:hover':{
    background:'#E0EDF0',
    borderRadius:'8px'
  }
})

const webStyle: any = {
  inviteMemberStyle:{
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
    margin: "0.5rem auto",
    paddingLeft:"12px"
  },
  
  BackBtn: {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  TeamName: {
    fontSize: "24px",
    fontWeight: "700",
    fontFamily: "poppins",
    paddingLeft: "1rem",
    display: "flex",
  },

  TopContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    padding: "6px 0px",
  },
  createIcon: {
    marginRight: "10px",
  },
  image: {
    width: "350px",
    height: "350px",
  },
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5rem",
  },
  moveText: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#237182",
    lineHeight: "26px",
    fontFamily: "Poppins",
    cursor: "pointer",
  },
  CardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  renameModalDelete: {
    margin: "auto",
    width: "584px",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  editModal: {
    margin: "auto",
    width: "600px",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  modalHeaderDelete: {
    display: "flex",
    color: "#0F172A",
    marginBottom: "1rem",
  },
  renameInputLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#334155",
    lineHeight: "1.375rem",
  },

  InputSearch: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "center",
  },
  InputSearchMove: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "left",
    flexDirection: "column",
    gap: "4px",
  },
  ListItemName: {
    fontSize: "16px",
    fontFamily: "poppins",
    lineHeight: "24px",
    fontWeight: "400",
  },
  ListItemEmail: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "22px",
    fontWeight: "400",
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "50px",
    padding: "4px",
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px",
  },
  PersonalInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "16px",
  },
  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "27px",
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 && {
      textAlign: "center",
    }),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px",
    marginTop: "20px",
    backgroundColor: "#f0f5f7",
  },
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  createTeamBtn: {
    color: "white",
    fontFamily: "Poppins",
    backgroundColor: "#237182",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px 10px 16px",
  },
  searchBox: {
    display: "flex",
  },
  sort: {
    marginRight: "15px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  mt10: {
    marginTop: "20px",
    padding: "0px 40px",
    flex: "1 1 auto", overflow: "auto"
  },
  teamSize: {
    marginRight: "15px",
    color: "#475569",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "white",
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
    width: "22%",
  },
  TableHeaderCenter: {
    textAlign: "center" as "center",
  },
  TableContentBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  SubBoxy: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
  },

  SubUserImg: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
  },
  SubDivA: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "start",
  },
  deleteIcon: {
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#DC2626",
    textAlign:'center'
  },

  SubDivI: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "2px 14px",
    borderRadius: "40px",
  },

  iconM: {
    marginRight: "8px",
    fontSize: "10px",
  },
  popMenu: {
    marginBottom: "10px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  popup: {
    padding: "20px !important",
    marginLeft: "-5px !important",
  },
  footer: {
    padding: "10px 41px",
  },
  footer2: {
    display: "flex",
    alignItems: "center",
    color: "#94A3B8",
    justifyContent: "space-between",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  footerFont: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },

  errorText: {
    fontSize: "0.75rem",
    color: "#DC2626",
    lineHeight: "1rem",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  clearAll: {
    textAlign: "center" as "center",
    color: "#237182",
    fontWeight: 600,
  },
  mainContainer: {
    display: "flex",
    alignContent: "center",
    width: "1000%",
    justifyContent: "space-between",
  },
};

const webStyles = {
  finishTextStyle: {
    width: "240px",
  },
  RoleButton: {
    color: "#64748B",
    fontSize: "16px",
    fontWeight: 600,
    padding: "10px 12px 10px 12px",
    display: "flex",
    alignItems: "center",
    width: "33%",
    borderRadius: "50px",
    justifyContent: "center",
    textTransform: "capitalize",
    fontFamily: "Poppins",
  },
  RoleSelectedButton: {
    color: "#325962",
    backgroundColor: "#E0EDF0",
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #D3E4E9",
    borderRadius: "50px",
    padding: "10px 12px 10px 12px",
    display: "flex",
    alignItems: "center",
    width: "33%",
    justifyContent: "center",
    textTransform: "capitalize",
    fontFamily: "Poppins",
  },
  expandIcon: {
    color: "#237182",
  },
  modalTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
  },
  closeIcon: {
    color: "#0F172A",
    cursor: "pointer",
  },
  selectedPlan: {
    border: "2px solid #237182",
  },
  paymentItemTitle: {
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: "1.75rem",
    marginBottom: "1.5rem",
  },
  priceNumber: {
    fontSize: "1.875rem",
    fontWeight: 700,
    lineHeight: "2.5rem",
  },
  planType: {
    fontSize: "0.875rem",
  },
  discountLabel: {
    position: "absolute" as "absolute",
    display: "inline-block",
    backgroundColor: "#b3d4db",
    clipPath: "polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%)",
    color: "white",
    textAlign: "center" as "center",
    padding: "8px",
    fontSize: "0.875rem",
    fontWeight: 700,
    fontFamily: "Poppins, sans-serif",
    right: "12px",
    top: 0,
  },
};

// Customizable Area End
