// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const UploadSvg = require("../assets/upload.png");
export const DeleteSvg = require("../assets/delete.png");
export const FileSvg = require("../assets/file.svg");
export const DownloadSvg = require("../assets/download.png");
export const GreenCheckSvg = require("../assets/greencheck.svg");
export const CalendarIcon = require("../assets/calendar.png");
export const CloseIcon = require("../assets/close.svg");
export const unCheckIcon = require("../assets/checkbox_.png")
export const checkedIcon = require("../assets/checkedbox.png")
export const success = require("../assets/success.png")
export const nextBtnClick = require("../assets/RightArrowClose.svg")
export const downArrowBtn = require("../assets/DownArrow.svg")
export const UpArrowBtn = require("../assets/UpArrow.svg")
export const UploadImageBtn = require("../assets/UploadImageBtn.svg")
export const PdfBtnImage = require("../assets/PdfButtonImage.svg")
export const threeDotIcon = require("../assets/threeDotIcon.svg")


// Customizable Area End
